import React, { useEffect, useState, useContext } from "react";
import Header from './Helpers/Header';
import {Box, Modal, Button} from '@material-ui/core';
import UserSignUp from './User/UserSignUp';
import PresentationAdd from './Presentations/PresentationAdd';
import CategoriesPopup from './Presentations/CategoriesPopup';
import PresentationBlock from "./Presentations/PresentationBlock";
import '../assets/scss/presentations.scss';
import AppContext from './App/AppContext';
import PresentationsContext from './Presentations/PresentationsContext';
import {handlingAfterFetch} from './Helpers/ToolsComponents';
import {
    fetchExistingPresentations, 
    fetchExistingPresentationCategories} from '../services/presentations_api';
import LoginContext from "./User/LoginContext";
import { PERMISSIONS_ADMIN, PERMISSIONS_STUDENT, PERMISSIONS_TEACHER } from "./User";
import { Link } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "39.3%",
    height: "77vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function ScreenPresentations(props) {
    
    const {setCurrentScreen, loggedInUser, setAlertObject} = useContext(AppContext);     
    const {cleanUserData} = useContext(LoginContext);  
  
    const [existingPresentations, setExistingPresentations] = useState([]);
    const [existingCategories, setExistingCategories] = useState([]);
    const [addUser, setAddUser] = useState("");

    const fetchData = async () => {
        handlingAfterFetch(
            fetchExistingPresentationCategories(), 
            (result) => {
                setExistingCategories(result.data);                       
            },
            setAlertObject
        );
        handlingAfterFetch(
            fetchExistingPresentations(), 
            (result) => {
                setExistingPresentations(result.data);                       
            },
            setAlertObject
        );
    };

    const addPresentationToList = (d) => setExistingPresentations([...existingPresentations, d]);
    
    const addCategoryToList = (d) => setExistingCategories([...existingCategories, d]);

    useEffect(() => {
        // code to run on component mount
        setCurrentScreen("Presentations")
        fetchData();        
    }, [])

    const presentationsContextData = { 
        existingPresentations, setExistingPresentations,
        existingCategories, setExistingCategories
    };
    const closeAddUserModal = () => {
        setAddUser("");        
    }
    const openAddUserModalStudent = () => {
        setAddUser(PERMISSIONS_STUDENT);
    }
    const openAddUserModalTeacher = () => {
        setAddUser(PERMISSIONS_TEACHER);
    }
    return (
        <PresentationsContext.Provider value={presentationsContextData}>
            <Header />
            <div className="pres-container">
                <div className="register-wrapper">
                    <Link to="/learning-system/home/" className="primary-brand-bckg system-black"> <HomeIcon/> Back to Home</Link>
                </div>
                <Modal
                    open={(addUser != "")}
                    onClose={closeAddUserModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Button className="close-modal-btn" onClick={closeAddUserModal}>
                            Back
                        </Button>
                        {addUser===PERMISSIONS_STUDENT && <UserSignUp userPermissions={PERMISSIONS_STUDENT} handlerOnSubmit={closeAddUserModal}/>}
                        {addUser===PERMISSIONS_TEACHER && <UserSignUp userPermissions={PERMISSIONS_TEACHER} handlerOnSubmit={closeAddUserModal}/>}
                    </Box>
                </Modal>
                <div className="wrapper">
                    <PresentationAdd addPresentation = {addPresentationToList} />
                    <CategoriesPopup addCategory = {addCategoryToList}/>
                </div>
                <div className="container-block1">
                    <h2>MY PRESENTATIONS</h2>
                        {existingCategories.map(cat => {
                            const filteredPresentations = existingPresentations.filter(pres => (pres.category === cat.id));
                            if (filteredPresentations.length === 0) return null;
                            return (
                                <div className="category-block">
                                    <h3>{cat.categoryName}</h3>
                                    <div className="item-blocks">
                                        {
                                            filteredPresentations.map(presentationData =>  
                                                <PresentationBlock 
                                                    presentationData={presentationData} 
                                                    key={presentationData.id} 
                                                    existingPresentations={existingPresentations} 
                                                    setExistingPresentations={setExistingPresentations} 
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
        </PresentationsContext.Provider>
    ); 
}

export default ScreenPresentations;