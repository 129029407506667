import React, { useState, useContext } from "react";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {FormControl, InputLabel, Input, Button, Backdrop, Box, Modal, Fade, Checkbox, Select, MenuItem } from '@material-ui/core';
import '../../assets/scss/presentations.scss';
import AppContext from '../App/AppContext';
import PresentationsContext from './PresentationsContext';
import {SUCCESS_ALERT_OBJECT} from '../Helpers/AlertPopup'
import {handlingAfterFetch} from '../Helpers/ToolsComponents'
import { createPresentation } from "../../services/presentations_api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PresentationAdd(props) {
    const {setAlertObject} = useContext(AppContext);       
    const {existingCategories} = useContext(PresentationsContext);   

    const [open, setOpen] = useState(false);
    const [presentationName, setpresentationName] = useState('');
    const [categoryId, setCategoryId] = useState(1);

    const handleOnCategoryChange = (event) => {
        setCategoryId(Number(event.target.value));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
   
    const addPresentation = async (e) => {
        e.preventDefault();
        await handlingAfterFetch(
            createPresentation({presentationName, category: categoryId}), 
            (result) => {
                props.addPresentation(result.data);
                handleClose();
                setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    message: `Presentation <${presentationName}> has been added successfully!
                    Close this popup to continue...`
                });
            },
            setAlertObject
        );
    } 

    return (
        <div className="present-container">
            <div className="container-block1">
                <h2>Add New Presentation</h2> <Button variant="contained" className="primary-brand-bckg system-black" onClick={handleOpen}> <AddIcon/> </Button>
            </div>
            <div className="modal">  
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                    <Box sx={style}>
                        <Button className="close-modal-btn" onClick={handleClose}>
                            <CloseIcon />
                        </Button>  
                        <FormControl>
                            <InputLabel htmlFor="name-input">Presentation Name</InputLabel>
                            <Input id="name-input" aria-describedby="my-helper-text" onChange={(e) => {setpresentationName(e.target.value)}} />
                        </FormControl>
                        <div className="select-cat">
                            <InputLabel id="demo-simple-select-label">Set Category:</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={categoryId}
                                label="Age"
                                onChange={handleOnCategoryChange}
                            >
                            {
                                existingCategories.map((cat) =>  
                                    <MenuItem key={cat.id} value={cat.id}>{cat.categoryName}</MenuItem>
                                ) 
                            }  
                            </Select>     
                        </div>
                        <Button variant="contained" className="primary-brand-bckg" onClick={addPresentation}> ADD PRESENTATION </Button>
                    </Box>
                    </Fade>
                </Modal>
            </div>
        </div>
    )
}

export default PresentationAdd;