import React, { useState, useContext } from "react";
import {Button, Backdrop, Modal, Fade, Box, TextField, FormControlLabel, Checkbox, FormGroup} from '@material-ui/core';
import Header from "../Helpers/Header";
import * as ReactQuill from 'react-quill';
import "../../../node_modules/react-quill/dist/quill.snow.css";
import { Dropzone, FileItem } from "dropzone-ui";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AppContext from "../App/AppContext";
import { WARNING_ALERT_OBJECT, SUCCESS_ALERT_OBJECT } from "../Helpers/AlertPopup";
import LessonFileBlock from "./LessonFileBlock";


const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

const modules = {
    toolbar: [
        [{header:"1"}, {header:"2"}, {header: [3,4,5,6]}, {font: []}],
        [{size: []}],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{list:"ordered"}, {list:"bullet"}],
        ["link", "image", "video"], 
        ["clean"],
        ["code-block"],
    ],
    clipboard: { matchVisual: false }
};

const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "code-block",
];

function EditLesson({lessonData, postActionHandler, deleteLessonInList, chapterData, courseName}) {
    const {setAlertObject, setDialogObject, handlerUploadFile} = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const [lessonName, setLessonName] = useState((lessonData && lessonData.lessonName)? lessonData.lessonName: '');
    const [lessonContent, setLessonContent] = useState((lessonData && lessonData.lessonContent)? lessonData.lessonContent: '');
    const [existingFiles, setExistingFiles] = useState((lessonData && lessonData.files)? lessonData.files: []);
    const [attFiles, setAttFiles] = useState([]);
    const [fileSrc, setFileSrc] = useState(undefined);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const doDeleteLesson = () => {
        deleteLessonInList(lessonData);
        setAlertObject({...WARNING_ALERT_OBJECT, 
            severity:'success',
            message: `Lesson <${lessonName}> has been deleted!
            But any changes will be applied after course saving!`
        });
    }

    const deleteFileInList = (d) => {       
        let updatedList = [...existingFiles];
        updatedList = updatedList.filter(file_data => (d.createdAt !== file_data.createdAt));
        setExistingFiles(updatedList);
    };

    const handleDeleteLesson = (e) => {
        e.preventDefault();
        const deleteLessonConfig = {
            open:true, 
            ok:"DELETE", cancel:"CANCEL",
            title:`Delete lesson: ${lessonName}?`, 
            description: "Please, review and press DELETE to remove this Lesson or CANCEL to decline the action.",
            content: null,
            call_on_submit: doDeleteLesson
        };
        setDialogObject(deleteLessonConfig);         
    }

    const handleChangeText = (e) => {
        setLessonContent(e);
    }

    const handleOnChange = (incommingFiles) => {
        setAttFiles(incommingFiles);
    };

    const handleOnDelete = (id) => {
        setAttFiles(attFiles.filter((x) => x.id !== id));
    };
    const handleOnSee = (fileSource) => {
        setFileSrc(fileSource);
    };

    //TODO: we should refactor it and think how to remove uploaded files 
    //      if finally changes in course were not saved
    const uploadFiles = async () => {
        if (attFiles.length === 0) return existingFiles;
        let allNewlyAddedFiles = []
        for (let index = 0; index < attFiles.length; index++) {
            const fileObj = attFiles[index];
            //upload file to server
            const fileData = await handlerUploadFile(fileObj.file);
            //create slide
            if (fileData){
                allNewlyAddedFiles.push({fileName: fileData.originalname, fileUrl:fileData.fileUrl});
            }
        }
        if (allNewlyAddedFiles.length > 0){
            allNewlyAddedFiles = [...existingFiles, ...allNewlyAddedFiles];
            setExistingFiles(allNewlyAddedFiles);
            setAlertObject({...SUCCESS_ALERT_OBJECT, 
                message: `${allNewlyAddedFiles.length} files have been uploaded to the current lesson successfully!`
            });
        }
        return allNewlyAddedFiles;
    }

    const handleLessonUpsertion = async (e) => {
        e.preventDefault();
        const files = await uploadFiles();
        const dataForUpdating =  {          
                lessonName,
                lessonContent,
                files
            };
        if (lessonData) {
            dataForUpdating.createdAt = lessonData.createdAt;
        }
        
        postActionHandler(dataForUpdating); 
        handleClose();
    } 

    return (
        <>
            <Button variant={lessonData ? "" : "contained"} className={lessonData? "system-black" : "secondary-disabled-bckg system-black" } onClick={handleOpen}> {lessonData? <EditIcon/> : "+ Add Lesson" } </Button> 
            {lessonData && <Button className="system-black" onClick={handleDeleteLesson}><DeleteIcon/></Button>}

            <div className="modal">  
                <Modal
                    className="modal-add-lesson"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                    <Box sx={style}>  
                        <div className="container">
                            <Header/>
                            <div className="info">
                                <h2>{courseName}</h2>
                                <h3>{chapterData && chapterData.chapterName }</h3>
                            </div>
                            <TextField className="lesson-name" label="Lesson Name" variant="outlined" defaultValue={lessonName} onChange={(e) => {setLessonName(e.target.value)}} />
                            <ReactQuill 
                                value={lessonContent} 
                                onChange={handleChangeText} 
                                modules={modules}
                                formats={formats}
                                placeholder="Write something..."
                            />
                            <div className="files">
                                <h3>Attach files to lesson</h3>

                                <Dropzone
                                    onChange={handleOnChange}
                                    value={attFiles}
                                    maxFiles={5}
                                    maxFileSize={2100000}
                                    accept={'.pdf, .doc, .docx, .odt, .pdf, .rtf, .txt, .ppt'}
                                    >
                                    {attFiles.map((file) => (
                                        <FileItem key={file.id}
                                        {...file} 
                                        onDelete={handleOnDelete}
                                        onSee={handleOnSee}
                                        preview
                                        info
                                        hd
                                        />
                                    ))}
                                </Dropzone>
                                <div className="existing-files">
                                    {existingFiles.map((fileData, index) => 
                                        <LessonFileBlock
                                            key={index} 
                                            fileData={fileData} 
                                            deleteFileInList = {deleteFileInList}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="buttons-bottom">
                                <Button variant="contained" className="primary-disabled-bckg system-black" onClick={handleClose}> DISCARD </Button>
                                <Button variant="contained" className="primary-brand-bckg system-black" onClick={handleLessonUpsertion} > SAVE </Button>
                            </div>
                        </div>
                    </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}

export default EditLesson;
