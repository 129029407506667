import {fetchGet, fetchPost, fetchDelete, fetchPut, SERVER_ENDPOINT} from './index'

export const DEFAULT_COURSE_CATEGORY_ID = 1;

export const fetchExistingCourses = async () => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/courses`, "Getting course data");
} 

export const createCourse = async (data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/courses`, data, 'application/json', "Adding course")    
}

export const deleteCourse = async (id) => {
    return await fetchDelete(`${SERVER_ENDPOINT}/api/courses/${id}`, {}, 'application/json', "Deleting course")    
}

// export const deleteLessonFile = async (id) => {
//     return await fetchDelete(`${SERVER_ENDPOINT}/api/courses/lessons/files/${id}`, {}, 'application/json', "Deleting course" )
// }

export const updateCourse = async (data) => {
    return await fetchPut(
        `${SERVER_ENDPOINT}/api/courses/${data.id}`, 
        data, 
        'application/json', "Updating course")    
}

export const fetchExistingCoursesCategories = async () => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/courses/categories`, "Getting course category data");
} 

export const addCourseCategory = async (category_data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/courses/categories`, category_data, 'application/json', "Adding presentation category");
} 

export const updateCategory = async (category_data) => {
    return await fetchPut(
        `${SERVER_ENDPOINT}/api/courses/categories/${category_data.id}`, 
        category_data, 
        'application/json', "Updating category"
    )    
} 

export const deleteCategory = async (id) => {
    return await fetchDelete(`${SERVER_ENDPOINT}/api/courses/categories/${id}`, {}, 'application/json', "Deleting category")    
}

export const fetchAllCategoriesOfCourse = async (id) => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/courses/${id}/categories`, "Fetching categories of course")    
}

export const fetchExistingUsers = async () => {
   return await fetchGet(`${SERVER_ENDPOINT}/api/users`, "Getting users data");
} 
