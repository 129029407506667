import React, { useState, useContext , useEffect} from "react";
import {Button, Backdrop, Modal, Fade, Box, TextField, FormControlLabel, Checkbox, FormGroup} from '@material-ui/core';
import AppContext from "../App/AppContext";
import CoursesContext from "./CoursesContext";
import Header from "../Helpers/Header";
import EditChapter from "./EditChapter";
import { DEFAULT_COURSE_CATEGORY_ID, createCourse, updateCourse } from "../../services/courses_api";
import {SUCCESS_ALERT_OBJECT} from '../Helpers/AlertPopup';
import {handlingAfterFetch} from '../Helpers/ToolsComponents';
import user from '../../assets/images/user.png';
import { Dropzone, FileItem } from "dropzone-ui";
import StudentAssignedToCourses from "./StudentAssignedToCourses";
import { SERVER_ENDPOINT } from "../../services";
import {addCourseToUser, fetchExistingStudents} from '../../services/users_api';
import { PERMISSIONS_STUDENT } from "../User";

const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

function EditCourse({courseData, postActionHandler}) {
    const {setAlertObject, uploadFirstImageIfProvided} = useContext(AppContext);       
    const {existingCategories, existingStudents} = useContext(CoursesContext);  
    
    const [open, setOpen] = useState(false);
    const [courseName, setCourseName] = useState((courseData && courseData.courseName) ? courseData.courseName : "");
    const [categoryIds, setCategoryIds] = useState((courseData && courseData.categoryIds) ? courseData.categoryIds : [DEFAULT_COURSE_CATEGORY_ID]);
    const [sendEmail, setSendEmail] = useState((courseData && courseData.hasOwnProperty("sendEmail")) ? (courseData.sendEmail === 1 || courseData.sendEmail === true ? true : false) : false);
    const [existingChapters, setExistingChapters] = useState((courseData && courseData.chapters) ? courseData.chapters : []);
    const [courseStudents, setCourseStudents] = useState(courseData && Array.isArray(courseData.students) ? courseData.students : []);

    //img
    const [imageFiles, setImageFiles] = useState([]);
    const [imageSrc, setImageSrc] = useState(undefined);

    const handleOnChange = (incommingFiles) => {
        setImageFiles(incommingFiles);
    };

    const handleOnDelete = (id) => {
        setImageFiles(imageFiles.filter((x) => x.id !== id));
    };
    const handleOnSee = (imageSource) => {
        setImageSrc(imageSource);
    };
//end img
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleToggleCategory = (e) => {
        const toggledCategoryId = Number(e.target.value);
        if (toggledCategoryId == DEFAULT_COURSE_CATEGORY_ID 
                && categoryIds.size == 1 
                && categoryIds.includes(toggledCategoryId))
        {
            e.preventDefault();
            return;
        }
        e.target.classList.toggle("active");
        let updateCategoryIDs;
        if (e.target.classList.contains("active")){
            updateCategoryIDs = new Set([...categoryIds, toggledCategoryId]);
        }else{
            updateCategoryIDs = new Set([...categoryIds])
            updateCategoryIDs.delete(toggledCategoryId);
            if (updateCategoryIDs.size === 0){
                updateCategoryIDs.add(DEFAULT_COURSE_CATEGORY_ID);
            }
            
        }
        setCategoryIds([...updateCategoryIDs]);
    };

    const upsertCourse = async (e) => {
        e.preventDefault();
        const dataForUpsertion = {
            courseName, 
            "sendEmail": (sendEmail? 1 : 0), 
            "categoryIds": [...categoryIds],
            "chapters": existingChapters,
            "students": courseStudents
        };
        
        const courseImageUrl = await uploadFirstImageIfProvided(imageFiles);
        if (courseImageUrl) dataForUpsertion.courseImageUrl = courseImageUrl;
        let action_text = "added";
        let action_handler = createCourse;
        if (courseData && courseData.id > 0){            
            dataForUpsertion.id = courseData.id;
            action_text = "updated";
            action_handler = updateCourse;
        }
       
        await handlingAfterFetch(
            action_handler(dataForUpsertion), 
            (result) => {
                postActionHandler(result.data);
                handleClose();
                setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    message: `Course <${courseName}> has been ${action_text} successfully!
                    Close this popup to continue...`
                });
            },
            setAlertObject 
        );
    } 

    const manageCourseStudents = (updatedStudents) => {
        setCourseStudents([...updatedStudents]);
    };

    const addNewChapter = () => {
        const newChapter = {chapterName:"New Chapter", stopAfter:0, minTimeToFinish:0, createdAt: new Date(), courseName: courseName};
        setExistingChapters([...existingChapters, newChapter])
    };
    
    const updateChapterInList = (d) => {       
        let updatedListOfChapters = [...existingChapters];
        updatedListOfChapters = updatedListOfChapters.map(chapter => {
            if (d.createdAt === chapter.createdAt) return d;
            return chapter;
        });
        setExistingChapters(updatedListOfChapters);
    };

    const deleteChapterInList = (d) => {       
        let updatedListOfChapters = [...existingChapters];
        updatedListOfChapters = updatedListOfChapters.filter(chapter => (d.createdAt !== chapter.createdAt));
        setExistingChapters(updatedListOfChapters);
    };

    return (
        <>
            <Button onClick={handleOpen} variant="contained" className="button primary-brand-bckg system-black">{(courseData && courseData.id)? "EDIT" : "+ Add course" }</Button>
            <div className="modal">  
                <Modal
                    className="modal-add-course"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>  
                            <div className="container">
                                <Header/>
                                <TextField className="course-name" label="Course Name" defaultValue={courseName} variant="outlined" onChange={(e) => {setCourseName(e.target.value)}} />
                                <div className="categories">
                                    {
                                        existingCategories.map((cat) =>  
                                            <button 
                                                key={cat.id} 
                                                value={cat.id} 
                                                onClick={handleToggleCategory} 
                                                className={`cat-btn ${(categoryIds && categoryIds.includes(cat.id)) ? 'active': ''}`}
                                            > {cat.categoryName} </button>
                                        )
                                    }      
                                </div>
                                <FormGroup>
                                    <FormControlLabel control={
                                            <Checkbox 
                                                color="primary" 
                                                defaultChecked={sendEmail}
                                                onChange={(e) => {setSendEmail(e.target.checked)}}
                                            />
                                        } label="Email sellers after VA complete this course" />
                                </FormGroup>
                                <div className="img-container">
                                    <h3>Upload course image</h3>
                                    <Dropzone
                                        onChange={handleOnChange}
                                        value={imageFiles}
                                        maxFiles={1}
                                        maxFileSize={3998000}
                                        accept={'image/*'}
                                        >
                                        {imageFiles.map((file) => (
                                            <FileItem key={file.id}
                                            {...file} 
                                            onDelete={handleOnDelete}
                                            onSee={handleOnSee}
                                            preview
                                            info
                                            hd
                                            />
                                        ))}
                                    </Dropzone>           
                                </div>
                                <div className="chapters">
                                    {existingChapters.map((chapterData, index)=> 
                                        <EditChapter 
                                            key={index} 
                                            chapter_index={index} 
                                            chapterData={chapterData}
                                            courseName={courseName}
                                            updateChapterInList={updateChapterInList}
                                            deleteChapterInList={deleteChapterInList}
                                        >
                                        </EditChapter>
                                    )}
                                    <Button variant="contained"  className="add-btn primary-brand-bckg system-black" onClick={addNewChapter}>+ ADD CHAPTER</Button>
                                </div>
                                <div className="assistants">
                                    <h4>Assigned VA's</h4>                                 
                                    {courseStudents.map((studentId, index)=>{
                                        let existUser = existingStudents.find(existingStudent => (existingStudent.id == studentId))
                                        if (!existUser) return null;
                                        return (
                                            <div className="existing-as" key={existUser.id}>
                                                <div className="img-content">
                                                    <img src={existUser.userImageURL ? `${SERVER_ENDPOINT}${existUser.userImageURL}` : user}/>
                                                </div>
                                                <div className="info-content">
                                                    <p className="name">{existUser.fullName}</p>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <div className="btn"> 
                                        <StudentAssignedToCourses 
                                            manageCourseStudents={manageCourseStudents} 
                                            courseStudents={courseStudents}
                                            courseName={courseName} 
                                            catIds = {categoryIds}
                                        />
                                    </div>
                                </div>
                                <div className="buttons-bottom">
                                    <Button variant="contained" className="primary-disabled-bckg system-black" onClick={handleClose}> DISCARD </Button>
                                    <Button variant="contained" className="primary-brand-bckg system-black"  onClick={upsertCourse} >{(courseData && courseData.id)? "UPDATE" : "SAVE" }</Button>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}

export default EditCourse;