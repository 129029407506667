import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Header from './Helpers/Header';
import icon_eye from '../assets/images/ci_plus.png';
import '../assets/scss/home_student.scss';
import CoursesContext from "./LearningSystem/CoursesContext";
import {handlingAfterFetch} from './Helpers/ToolsComponents';
import {
    fetchExistingCourses, 
    fetchExistingCoursesCategories} from '../services/courses_api';
import AppContext from "./App/AppContext";
import CourseBlockStudent from "./LearningSystem/CourseBlockStudent";
import { getCourseOfStudent } from "../services/users_api";
import { updateQuestionStateInDB } from "../services/states_api";
import { SUCCESS_ALERT_OBJECT } from "./Helpers/AlertPopup";
import { composeEmailForCourseForStudent, fetchEmailsForCourseForStudent } from "../services/sent_emails_api";
import CustomPreLoader from "./Helpers/CustomPreLoader";


function ScreenHomeForStudents(){
    const {setAlertObject, loggedInUser} = useContext(AppContext);

    const [existingCourses, setExistingCourses] = useState([]);
    const [existingCategories, setExistingCategories] = useState([]);
    const [assignedCourses, setAssignedCourses] = useState([]);
    const [clissifiedCourses, setClassifiedCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const sendEmailsForFinishedCourses = async () => {
        for (let i = 0; i < clissifiedCourses.length; i++) {
            const course = clissifiedCourses[i];
            if (course.data 
                && course.data.hasOwnProperty("first_not_completed_chapter") 
                && course.data.first_not_completed_chapter === -1) {
                //finished course
                if(course.data.sendEmail === true || course.data.sendEmail === 1){
                    //check by course id and student id if we didn't send email before
                    await handlingAfterFetch(
                        composeEmailForCourseForStudent(course.courseId, loggedInUser.id, loggedInUser),
                        (result) => {
                            //console.log(result);
                        },
                        setAlertObject
                    );
                }                
            }
        }   
    }

    const classifyCourses = () => {
        if (assignedCourses.length === 0 || existingCourses.length === 0){ 
            setIsLoading(false);
            return;
        }
        const updatedClassifiedCourses = assignedCourses.map(assignedCourse => {
            const filteredCourse = existingCourses.find(c => (c.id == assignedCourse.courseId));
            if (!filteredCourse) return assignedCourse;
            let completed_chapters = 0;
            const first_not_completed_chapter = filteredCourse.chapters.findIndex(chapter => {
                if (chapter.tests.length > 0){

                  let all_tests_ok = true;
                  
                  chapter.tests.forEach(test => {
                    if (test.questions && test.questions.length > 0){
                        console.log("ins")
                      let student_question_states = []
                      if (test.question_states && test.question_states.length > 0){
                        student_question_states = test.question_states.filter(qs => qs.studentId === loggedInUser.id)
                      }
                      if (test.questions.length !== student_question_states.length){
                        all_tests_ok = false;
                      }else if (chapter.stopAfter === 1 || chapter.stopAfter === true){
                        const exists_at_least_one_not_reviewed = student_question_states.find(qs => (qs.reviewed === 0 || !qs.reviewed));
                        if (exists_at_least_one_not_reviewed) {
                            all_tests_ok = false;
                        }
                      }
                    }
                  })
                  if (!all_tests_ok) return true; //if not all questions ok we return TRUE (!FALSE) если не все впорядке мы возвращаем найденный индекс(нашли 1 незаконченный или непроверенный)        
                }
                //new
                if (chapter.tests.length === 0){
                    return true;
                }
                //endnew
                completed_chapters++;
                return false;
            })  
            return {...assignedCourse, data: {...filteredCourse, first_not_completed_chapter, completed_chapters}}
        })
        setClassifiedCourses(updatedClassifiedCourses);
        setIsLoading(false);
    }

    const fetchData = async () => {
        handlingAfterFetch(
            await  fetchExistingCourses(),
            (result) => {
                setExistingCourses(result.data);
            },
            setAlertObject
        );
        handlingAfterFetch(
            fetchExistingCoursesCategories(), 
            (result) => {
                setExistingCategories(result.data);
            },
            setAlertObject
        );
        handlingAfterFetch(
            await getCourseOfStudent(loggedInUser.id), 
            (user_res) => {
                setAssignedCourses(user_res.data);
            },
            setAlertObject
        );
    };

    useEffect(() => {
        fetchData();        
    }, [])

    useEffect(() => {
        classifyCourses();        
    }, [existingCourses, assignedCourses])

    useEffect(() => {
        sendEmailsForFinishedCourses();        
    }, [clissifiedCourses])

    const updateQuestionStateInCourses = (question_state) => {        
        let updatedCourses = [...existingCourses];
        let affectedChapter = null;
        let affectedTest = null;
        let affectedCourse = updatedCourses.find(course => {
            let chapter = course.chapters.find(chapter => {
                let test = chapter.tests.find(t => {
                    const foundTest = t.questions.find(q => q.id === question_state.questionId)
                    if (foundTest) return true;
                    return false;
                })
                if (test) {
                    affectedTest = test;
                    return true;
                }
                return false;
            })
            if (chapter) {
                affectedChapter = chapter;
                return true;
            }
            return false;
        })

        if (affectedCourse){            
            if (affectedTest.question_states.find(qs => qs.questionId === question_state.questionId)){
                affectedTest.question_states = affectedTest.question_states.map(qs => {
                    if (qs.questionId === question_state.questionId) return question_state;
                    return qs;
                })    
            }else{
                affectedTest.question_states.push(question_state);
            }
            affectedChapter.tests = affectedChapter.tests.map(test => {
                if (test.id === affectedTest.id) return affectedTest;
                return test;
            })
            affectedCourse.chapters = affectedCourse.chapters.map(chapter => {
                if (chapter.id === affectedChapter.id) return affectedChapter;
                return chapter;
            })
            updatedCourses = updatedCourses.map(course => {
                if (course.id === affectedCourse.id) return affectedCourse;
                return course;
            })
            setExistingCourses(updatedCourses);
        }
    }

    const upsertQuestionState = async (question_state) => {

        await handlingAfterFetch(
            updateQuestionStateInDB(question_state), 
            (result) => {    
                updateQuestionStateInCourses(result.data);
                // setAlertObject({...SUCCESS_ALERT_OBJECT, 
                //     message: `Question state has been updated successfully!
                //     Close this popup to continue...`
                // });
            },
            setAlertObject 
        );
    }

    const coursesContextData = { 
        existingCourses, setExistingCourses,
        existingCategories, setExistingCategories,
        upsertQuestionState
    };

    return(
        <CoursesContext.Provider value={coursesContextData}>
            <div className="container-stud">
                <Header/>
                <h1>Ongoing Courses</h1>
                <div className="courses-container">
                    <div className="scrollable">
                        <div className="blocks">
                            {
                                clissifiedCourses.map(course => {
                                    const filteredCourse = existingCourses.find(course => (course.id == course.courseId));
                                    if (!course.data 
                                        || !course.data.hasOwnProperty("first_not_completed_chapter") 
                                        || course.data.first_not_completed_chapter === -1) {
                                        return null;
                                    }
                                    return (
                                        <CourseBlockStudent 
                                            key={course.courseId}
                                            courseData={course.data}
                                        />
                                    ) 
                                }) 
                            }
                        </div>
                    </div>
                </div>
                <h2>Finished</h2>
                <div className="courses-container">
                    <div className="scrollable">
                        <div className="blocks">
                        {
                            clissifiedCourses.map(course => {
                                const filteredCourse = existingCourses.find(course => (course.id == course.courseId));
                                if (!course.data 
                                    || !course.data.hasOwnProperty("first_not_completed_chapter") 
                                    || course.data.first_not_completed_chapter !== -1) {
                                    return null;
                                }
                                return (
                                    <CourseBlockStudent 
                                        key={course.courseId}
                                        courseData={course.data}
                                        finished={true}
                                    />
                                ) 
                            }) 
                        }
                        </div>
                    </div>
                </div>
                <Link to="/presentations/" className="eye-btn MuiButtonBase-root MuiButton-root MuiButton-contained primary-brand-bckg system-black"> <img src={icon_eye} alt="icon_eye"/> Presentations </Link>
            </div>
            {isLoading && <CustomPreLoader/> }
        </CoursesContext.Provider>
    )
}

export default ScreenHomeForStudents;