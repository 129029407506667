import React, { useEffect, useState, useContext } from "react";
import Header from './Helpers/Header';
import {Box, Modal, Button, Radio, Grid, CircularProgress} from '@material-ui/core';
import '../assets/scss/home_admin.scss';
import icon_ppl from '../assets/images/icon_ppl.png';
import icon_blue_user from '../assets/images/Group153.png';
import icon_eye from '../assets/images/ci_plus.png';
import UserSignUp from './User/UserSignUp';
import EditCourse from "./LearningSystem/EditCourse";
import { Link } from "react-router-dom";
import CourseCategoriesPopup from "./LearningSystem/CourseCategoriesPopup";
import CoursesContext from "./LearningSystem/CoursesContext";
import {handlingAfterFetch} from './Helpers/ToolsComponents';
import AppContext from "./App/AppContext";
import {
    fetchExistingCourses, 
    fetchExistingCoursesCategories} from '../services/courses_api';
import CourseBlock from "./LearningSystem/CourseBlock";
import ManagmentPanel from "./LearningSystem/ManagmentPanel";

import { PERMISSIONS_ADMIN, PERMISSIONS_TEACHER, PERMISSIONS_STUDENT } from './User';
import {fetchExistingStudents,getAllAssignedUsers,getAllUsers} from '../services/users_api';
import { fetchAllQuestionStates, getAllStudentsInCourseStates } from "../services/states_api";

import StudentAnswers from "./LearningSystem/StudentAnswers";
import CustomPreLoader from "./Helpers/CustomPreLoader";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "39.3%",
    height: "77vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function ScreenHomeForAdmin(props){

    const {loggedInUser, setCurrentScreen,  setAlertObject} = useContext(AppContext);

    const [addUser, setAddUser] = useState("");
    const [existingCourses, setExistingCourses] = useState([]);
    const [existingCategories, setExistingCategories] = useState([]);
    const [existingStudents, setExistingStudents] = useState([]);
    const [assignedStudents, setAssignedStudents] = useState([]);
    const [existingTeachers, setExistingTeachers] = useState([]);
    const [progressData ,setProgressData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const closeAddUserModal = () => {
        setAddUser("");        
    }

    const openAddUserModal = (permissions) => {
        setAddUser(permissions);
    }

    const fetchData = async () => {
        handlingAfterFetch(
            await fetchExistingCoursesCategories(), 
            (result) => {
                setExistingCategories(result.data);                      
            },
            setAlertObject
        );
        handlingAfterFetch(
            await fetchExistingCourses(), 
            (result) => {
                setExistingCourses(result.data);
            },
            setAlertObject
        );
        handlingAfterFetch(
            await getAllAssignedUsers(PERMISSIONS_STUDENT), 
            (result) => {
                setAssignedStudents(result.data);
            },
            setAlertObject
        );
        handlingAfterFetch(
            await getAllUsers(PERMISSIONS_TEACHER), 
            (result) => {
                setExistingTeachers(result.data || []);
            },
            setAlertObject
        );
        handlingAfterFetch(
            await getAllUsers(PERMISSIONS_STUDENT), 
            (result) => {
                setExistingStudents(result.data);
            },
            setAlertObject
        );  
        handlingAfterFetch(
            await getAllStudentsInCourseStates(), 
            (result) => {
                setProgressData(result.data);
            },
            setAlertObject
        );
        setIsLoading(false);     
    };

    const addCourseToList = (d) => setExistingCourses([...existingCourses, d]);
    
    const updateCourseInList = (d) => {
        let updatedListOfCourses = [...existingCourses];
        updatedListOfCourses = updatedListOfCourses.map(course => {
            if (d.id === course.id) return d;
            return course;
        });
        setExistingCourses(updatedListOfCourses);
    };
    
    const addCategoryToList = (d) => setExistingCategories([...existingCategories, d]);

    const coursesContextData = { 
        existingCourses, setExistingCourses,
        existingCategories, setExistingCategories,
        addCourseToList, updateCourseInList,
        existingStudents, setExistingStudents,
        assignedStudents, setAssignedStudents,
        fetchData
    };

    useEffect(() => {
        // code to run on component mount
        setCurrentScreen("");
        fetchData();     
    }, [])
 
    return(
        <CoursesContext.Provider value={coursesContextData}>
            <div className="container-admin">
                <Header/>
                    <div className="buttons-container">
                        <ManagmentPanel permissions={PERMISSIONS_STUDENT} existingUsers={existingStudents} img={icon_ppl} />
                        {loggedInUser.permissions === PERMISSIONS_ADMIN && <ManagmentPanel permissions={PERMISSIONS_TEACHER} existingUsers={existingTeachers} img={icon_ppl}/>}
                        <EditCourse postActionHandler = {addCourseToList}/>
                        <CourseCategoriesPopup addCategory = {addCategoryToList}/>
                        <Button variant="contained" className="button primary-brand-bckg system-black" onClick={()=>openAddUserModal(PERMISSIONS_STUDENT)}> + Add VA </Button>
                        {loggedInUser.permissions === PERMISSIONS_ADMIN && 
                            <>
                                <Button variant="contained" className="button primary-brand-bckg system-black" onClick={()=>openAddUserModal(PERMISSIONS_TEACHER)}> + Add training manager </Button>
                                <Button variant="contained" className="button primary-brand-bckg system-black" onClick={()=>openAddUserModal(PERMISSIONS_ADMIN)}> + Add admin </Button>
                            </>
                        
                        }
                    </div>
                    <Modal
                        open={(addUser !== "")}
                        onClose={closeAddUserModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="modal-register"
                    >
                        <Box sx={style}>
                            <Button className="close-modal-btn" onClick={closeAddUserModal}>
                                Back
                            </Button>
                            {addUser===PERMISSIONS_STUDENT && <UserSignUp userPermissions={PERMISSIONS_STUDENT} handlerOnSubmit={closeAddUserModal}/>}
                            {addUser==PERMISSIONS_TEACHER && <UserSignUp userPermissions={PERMISSIONS_TEACHER} handlerOnSubmit={closeAddUserModal}/>}
                            {addUser==PERMISSIONS_ADMIN && <UserSignUp userPermissions={PERMISSIONS_ADMIN} handlerOnSubmit={closeAddUserModal}/>}
                        </Box>
                    </Modal>
                    <div className="info-content">
                        <div className="content-block">
                            <img src={icon_blue_user} alt="icon_blue_user" className="blue-icon-user"/>
                            <div className="number" id="number-assistants">{existingStudents.length}</div>
                            <p>VA's</p>
                        </div>
                        <div className="content-block">
                            <img src={icon_blue_user} alt="icon_blue_user" className="blue-icon-user"/>
                            <div className="number" id="number-managers">{existingTeachers.length}</div>
                            <p>Training Managers</p>
                        </div>
                        <div className="content-block">
                            <img src={icon_blue_user} alt="icon_blue_user" className="blue-icon-user"/>
                            <div className="number" id="number-assistants-on-courses">{[...new Set(assignedStudents.map(item => item.studentId))].length}</div>
                            <p>VA's on courses</p>
                        </div>
                    </div>
                    <div className="courses-container">
                        <h1 className="title">Courses</h1>     
                        <div className="courses-content">

                            {existingCategories.map(cat => {
                                const filteredCourses = existingCourses.filter(course => (course.categoryIds.indexOf(cat.id) !== -1));
                                if (filteredCourses.length === 0) {
                                    return null;
                                }
                                return (
                                    <div className="category-block" key={cat.id}>
                                        <h3>{cat.categoryName}</h3>
                                        <div className="scrollable">
                                            <div className="item-blocks">
                                                {
                                                    filteredCourses.map((courseData, key) => {
                                                        let progressInfo = progressData?.filter(prdata=> (prdata.courseId === courseData.id)); 
                                                        return <CourseBlock 
                                                            key={courseData.id} 
                                                            courseData={courseData}
                                                            postActionHandler={updateCourseInList}
                                                            progressInfo={progressInfo}
                                                        /> 
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>   
                    </div>
                    <StudentAnswers/>
                <Link to="/presentations/" className="eye-btn MuiButtonBase-root MuiButton-root MuiButton-contained primary-brand-bckg system-black"> <img src={icon_eye} alt="icon_eye"/> Presentations </Link>
            </div>
            {isLoading && <CustomPreLoader/> }
        </CoursesContext.Provider>
    )
}

export default ScreenHomeForAdmin;