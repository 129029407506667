import {fetchGet, fetchPost, fetchDelete, fetchPut, SERVER_ENDPOINT} from './index'

export const uploadFile = async (image_file) => {
    const data = new FormData();
    data.append("file", image_file);
    return await fetchPost(
        `${SERVER_ENDPOINT}/api/uploads/files`, 
        data, 
        'multipart/form-data', 
        "Uploading file"
    )    
}
