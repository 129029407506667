import React, { useState, useContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@material-ui/core";
import EditLesson from "./EditLesson";
import EditTest from "./EditTest";
import DeleteIcon from '@material-ui/icons/Delete';
import AppContext from "../App/AppContext";
import { WARNING_ALERT_OBJECT } from "../Helpers/AlertPopup";

function EditChapter({chapter_index, chapterData, updateChapterInList, deleteChapterInList, courseName}) {
    const {setAlertObject, setDialogObject} = useContext(AppContext);

    const [chapterName, setChapterName] = useState((chapterData && chapterData.chapterName)? chapterData.chapterName: '');
    const [existingLessons, setExistingLessons] = useState((chapterData && chapterData.lessons)? chapterData.lessons: []);
    const [existingTests, setExistingTests] = useState((chapterData && chapterData.tests)? chapterData.tests: []);
    const [minTimeToFinish, setMinTimeToFinish] = useState((chapterData && chapterData.minTimeToFinish) ? chapterData.minTimeToFinish: 0);
    const [stopAfter, setStopAfter] = useState((chapterData && chapterData.hasOwnProperty('stopAfter')) ? (chapterData.stopAfter === true || chapterData.stopAfter=== 1 ? true : false): false);
  
    const doDeleteChapter = () => {
        deleteChapterInList(chapterData);
        setAlertObject({...WARNING_ALERT_OBJECT, 
            severity:'success',
            message: `Chapter <${chapterName}> has been deleted successfully!
            But any changes will be applied after course saving!`
        });
    }

    const handleDeleteChapter = (e) => {
        e.preventDefault();
        const deleteChapterConfig = {
            open:true, 
            ok:"DELETE", cancel:"CANCEL",
            title:`Delete chapter: ${chapterName}?`, 
            description: "Please, review and press DELETE to remove this Chapter or CANCEL to decline the action.",
            content: null,
            call_on_submit: doDeleteChapter
        };
        setDialogObject(deleteChapterConfig);         
    }

    const updateChapter = () => {
        const dataForUpdating = {
            createdAt: chapterData.createdAt,            
            chapterName,
            minTimeToFinish, 
            "stopAfter": (stopAfter ? 1 : 0), 
            "lessons": existingLessons,
            "tests": existingTests
        };
        if (chapterData.id) dataForUpdating.id = chapterData.id;
        updateChapterInList(dataForUpdating);
    }

    useEffect(() => {
        updateChapter();
    }, [chapterName, stopAfter, minTimeToFinish, existingLessons, existingTests])

    const addLessonToList = (d) => {
        d.createdAt = ''+new Date();
        setExistingLessons([...existingLessons, d]);
    }

    const updateLessonInList = (d) => {
        let updatedList = [...existingLessons];
        updatedList = updatedList.map(lesson => {
            if (d.createdAt === lesson.createdAt) return d;
            return lesson;
        });
        setExistingLessons(updatedList);
    }    

    const deleteLessonInList = (d) => {       
        let updatedList = [...existingLessons];
        updatedList = updatedList.filter(lesson => (d.createdAt !== lesson.createdAt));
        setExistingLessons(updatedList);
    };

    const addTestToList = (d) => {
        d.createdAt = ''+new Date();
        setExistingTests([...existingTests, d])
    };

    const updateTestInList = (d) => {
        let updatedList = [...existingTests];
        updatedList = updatedList.map(test => {
            if (d.createdAt === test.createdAt) return d;
            return test;
        });
        setExistingTests(updatedList);
    }

    const deleteTestInList = (d) => {       
        let updatedList = [...existingTests];
        updatedList = updatedList.filter(test => (d.createdAt !== test.createdAt));
        setExistingTests(updatedList);
    };
    

    return (
        <div className="chapter" >
            <div className="chapter-info">
                <div className="chapter-name">
                    <p> Chapter{chapter_index+1} - <TextField className="chapter-name-input" value={chapterName} label="Chapter Name" onChange={(e) => {setChapterName(e.target.value)}} /></p> 
                </div>
                <Button className="system-black" onClick={handleDeleteChapter}><DeleteIcon/></Button>
            </div>
            <div className="additional-info">
                <div className="time">
                    <p>Min. time to finish - <input type="number" id="tentacles" onChange={(e) => {setMinTimeToFinish(Number(e.target.value))}} defaultValue={minTimeToFinish} name="tentacles" min="0" max="100"/></p> <p>Days</p>
                </div>
                <FormGroup>
                    <FormControlLabel control={<Checkbox color="primary" onChange={(e) => {setStopAfter(e.target.checked)}} defaultChecked={stopAfter}/>} label="Stop after chapter" />
                </FormGroup>
            </div>
            <div className="chapter-lesson-block">
                {
                    existingLessons.map((lessonData, index) =>                          
                        <div className="item-blocks">
                            <p>Lesson: {lessonData.lessonName}</p>
                            <EditLesson 
                                key={index} 
                                lessonData={lessonData} 
                                postActionHandler={updateLessonInList}
                                deleteLessonInList={deleteLessonInList}/> 
                        </div>
                    )
                }
            </div>
            <div className="chapter-tests-block">
                {
                    existingTests.map((testData, index) =>                             
                        <div className="item-blocks">
                            <p>Test: {testData.testName}</p>
                            <EditTest
                                key={index} 
                                testData={testData} 
                                updateChapterInList={updateChapterInList}
                                postActionHandler={updateTestInList}
                                deleteTestInList={deleteTestInList}/> 
                        </div>
                    )
                }
            </div>
            <div className="chapter-buttons">                
                <EditLesson chapterData={chapterData} courseName={courseName} postActionHandler={addLessonToList}/>
                <EditTest chapterData={chapterData} courseName={courseName}  postActionHandler={addTestToList}/>
            </div>
        </div>       
    )
}

export default EditChapter;