import Axios from "axios";

export const SERVER_ENDPOINT = "https://app.vaaphilippines.com";
// export const SERVER_ENDPOINT = "http://localhost:3001";

const parseRequestResponse = async (request, description) => { 
    try {
        const response = await request;
        // console.log('response', response)
        const common_data = {status:response.status, statusText:response.statusText}
        if(response.status === 200){
            const result = response.data;
            if(result.success) {
                return { ...common_data, ...result};
            } else {
                return { ...common_data, message:`${description} error: ${result.message}`};
            }
        }
        else if (response.status === 401){
            // bad login data
            return { ...common_data, message: `Wrong user credentials or user session is expired! Please, re-login...`};
        }else{
            return { ...common_data, message: `Server has responded with eror status ${response.status} on getting ${description}: ${response.statusText}`};
        }
    } catch (error) {
        return { status: 0, statusText:'', message: `Unexpected error while processing ${description}: ${error}`};
    }
};

export const fetchGet = async (url, description) => {
    const result =  await parseRequestResponse(
        Axios.get(url, {
            withCredentials: true, 
            mode: 'cors', 
            credentials: 'include',
            headers: {
                'Access-Control-Allow-Origin': SERVER_ENDPOINT,
            }
        }), description
    );
    return result;
};

export const fetchPost = async (url, data, content_type='application/json', description='') => {

    return await parseRequestResponse(
        Axios.post(url, data, {
                withCredentials: true,
                mode: 'cors',
                //credentials: 'include',
                headers: { 
                    'Content-Type': content_type,
                    'Access-Control-Allow-Origin': SERVER_ENDPOINT,
                }
            }
        ), description
    );
};

export const fetchPut = async (url, data, content_type='application/json', description='') => {

    return await parseRequestResponse(
        Axios.put(url, data, {
                withCredentials: true,
                mode: 'cors',
                //credentials: 'include',
                headers: { 
                    'Content-Type': content_type ,
                    'Access-Control-Allow-Origin': SERVER_ENDPOINT,
                }
                
            }
        ), description
    );
};

export const fetchDelete = async (url, data, content_type='application/json', description='') => {
    return await parseRequestResponse(
        Axios.delete(url, {
                withCredentials: true,
                mode: 'cors',
                //credentials: 'include',
                headers: { 
                    'Content-Type': content_type,
                    'Access-Control-Allow-Origin': SERVER_ENDPOINT,
                },
                data
            }
        ), description
    );
};
