import React, {useState, useContext, useEffect} from "react";
import SlideAdd from "./SlideAdd";
import SlideBlock from "./SlideBlock";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Input, Button} from '@material-ui/core';
import { updatePresentation, updateSlide } from "../../services/presentations_api";
import {SUCCESS_ALERT_OBJECT} from '../Helpers/AlertPopup'
import {handlingAfterFetch} from '../Helpers/ToolsComponents'
import AppContext from '../App/AppContext';
import PresentationsContext from "./PresentationsContext";
import {Select, MenuItem, InputLabel } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function PresentationEdit({
        presentationData, 
        existingPresentations, setExistingPresentations, 
        existingSlides, setExistingSlides, 
        closePresentationEdit}) {
    
    const {setAlertObject} = useContext(AppContext);  
    const {existingCategories} = useContext(PresentationsContext); 

    const [name, setName] = useState(presentationData.name);
    const [category, setCategory] = useState(presentationData.category);

    const changePresentationName = (e) => {
        setName(e.target.value);
    }

    const changePresentationCategory = (e) => {
        setCategory(e.target.value);
    }

    const handleSavePresentation = async (e) => {
        e.preventDefault();
        const slideOrders = existingSlides.map((slide, index) => {return {id:slide.id, order:index}});
        await handlingAfterFetch(
            updatePresentation({id:presentationData.id, name, category, slideOrders}), 
            (result) => {
                const updatedPresentation = result.data;
                const updatedList = existingPresentations.map(s => (s.id === updatedPresentation.id ? updatedPresentation : s));
                setExistingPresentations([...updatedList])
                setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    message: `Presentation <${updatedPresentation.name}> has been updated successfully!`
                });
            },
            setAlertObject
        );

        for (let i = 0; i < existingSlides.length; i++) {
            const slideData = existingSlides[i];
            await handlingAfterFetch(
                updateSlide(slideData), 
                (result) => {
                    // console.log(result);
                    // setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    //     message: `Slide <${updatedSlide.name}> has been updated successfully!`
                    // });
                },
                // setAlertObject
            );
        }
    }

    //DnD
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const grid = 6;

    const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: grid * 2,
      margin: `0 ${grid}px 0 0`,
    
      // change background colour if dragging
      background: isDragging ? '#F8D6B6' : 'transparent',
    
      // styles we need to apply on draggables
      ...draggableStyle,
    });

    const onDragEnd= (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
          }
      
        const itemsTemp = reorder(
            existingSlides,
            result.source.index,
            result.destination.index
        );
    
        setExistingSlides(itemsTemp);
    }

    useEffect(() => {
    }, [existingSlides])

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'transparent' : 'transparent',
        display: 'flex',
        padding: grid,
        overflow: 'auto',
    });

    return (
        <div className="presentation-container">
            <div className="catName">
                <Input defaultValue={name} variant="standard" onBlur={(e)=>changePresentationName(e)} />
                <Button 
                    variant="contained" 
                    className="primary-brand-bckg" 
                    disabled={name?false:true}
                    onClick={handleSavePresentation}>Save</Button>
            </div>
            <div className="select-cat">
                <InputLabel id="demo-simple-select-label">Change Category:</InputLabel>
                <div className="select-container">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        label="Age"
                        onChange={(e)=>changePresentationCategory(e)}
                    >
                    {
                        existingCategories.map((cat) =>  
                            <MenuItem key={cat.id} value={cat.id}>{cat.categoryName}</MenuItem>
                        ) 
                    }  
                    </Select> 
                    <Button variant="contained" onClick={handleSavePresentation} className="primary-brand-bckg" disabled={name?false:true}>Save</Button>  
                </div>  
            </div>
            <h3> Slides: </h3>
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="light-bg"/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="secondary-bckg"
                >
                    <Typography className="light-bg">Add New Slide</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <SlideAdd 
                        presentation_id={presentationData.id} 
                        existingSlides={existingSlides} 
                        setExistingSlides={setExistingSlides}
                        key={presentationData.id}/>
                </AccordionDetails>
            </Accordion>
            <div className="item-blocks">    
                <DragDropContext onDragEnd={onDragEnd}> 
                    <Droppable droppableId="droppable" direction="horizontal"> 
                    {(provided, snapshot) => (  
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                            className="custom-dropping"
                        >   
                            {existingSlides.map((slideData, index) => (
                                <Draggable key={slideData.id} draggableId={`${slideData.id}`} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                    className="item-droppable-block"
                                    >
                                        <SlideBlock 
                                        slideData={slideData} 
                                        existingSlides={existingSlides} 
                                        setExistingSlides={setExistingSlides} 
                                        key={slideData.id}/>
                                    </div>
                            )}
                            </Draggable>         
                        ))}
                        {provided.placeholder}
                        </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div> 
    );

}

export default PresentationEdit;

