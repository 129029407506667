import { Button, Backdrop, Modal, Fade, Box } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import CloseIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon  from '@material-ui/icons/Delete';
import UserSignUp from "./UserSignUp";
import { handlingAfterFetch } from "../Helpers/ToolsComponents";
import { fetchExistingUserSellers, getCategoriesOfUsers, Hello, userDelete } from "../../services/users_api";
import AppContext from "../App/AppContext";
import LoginContext from "./LoginContext";
import { WARNING_ALERT_OBJECT } from "../Helpers/AlertPopup";
import CoursesContext from "../LearningSystem/CoursesContext";
import { PERMISSIONS_ADMIN } from '../User';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "39.3%",
    height: "77vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EditUser({user_info, userPermissions}) {
    const {setAlertObject, setDialogObject, loggedInUser} = useContext(AppContext);
    const {existingStudents, setExistingStudents} = useContext(CoursesContext);
    const [existingUserSellers, setExistingUserSellers] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setFullName(user_info.fullName);
        setEmail(user_info.email);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);
    const {setFullName, setEmail} = useContext(LoginContext);
    

    const fetchData = async () => {
        handlingAfterFetch(
            fetchExistingUserSellers(user_info.id), 
            (result) => {
                setExistingUserSellers(result.data);                     
            },
            setAlertObject
        );       
    };

    const doDeleteUser = async () => {
        await handlingAfterFetch(
            userDelete(user_info.id), 
            (result) => {
                //console.log(result);
                setExistingStudents(
                    [...existingStudents.filter(d => (d.id !== user_info.id))]
                );
                setAlertObject({...WARNING_ALERT_OBJECT, 
                    severity:'success',
                    message: `User <${user_info.fullName}> has been deleted successfully!
                    Close this popup to continue...`
                });
            },
            setAlertObject
        ); 
    }

    const handlerDeleteUser = (e) => {
        e.preventDefault();
        const deleteCourseConfig = {
            open:true, 
            ok:"DELETE", cancel:"CANCEL",
            title:`Delete VA: ${user_info.fullName}?`, 
            description: "Please, review and press DELETE to remove this VA or CANCEL to decline the action.",
            content: null,
            call_on_submit: doDeleteUser
        };
        setDialogObject(deleteCourseConfig);        
    } 

    useEffect(() => {
        // code to run on component mount
        fetchData();                      
    }, [])
  
    return (
        <>
            <Button className="light-system-bckg dark-system" onClick={handleOpen}><SettingsIcon/></Button>
            {loggedInUser.permissions === PERMISSIONS_ADMIN && <Button className="light-system-bckg dark-system delete" onClick={handlerDeleteUser}><DeleteIcon/></Button> }
            <div className="modal">  
                <Modal
                    className="modal-managment-popup"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                    <Box sx={style}> 
                        <CloseIcon onClick={handleClose}/> 
                        <UserSignUp user_info={user_info} userPermissions={userPermissions} closer={handleClose} seller_info={existingUserSellers}/>
                    </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}
