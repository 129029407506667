import React, { useEffect, useState, useContext } from "react";
import messageIcon from "../../assets/images/messageIcon.png";
import flagIcon from "../../assets/images/flagIcon.png";
import Flags from "./Flags";
import Answers from "./Answers";
import CoursesContext from "./CoursesContext";
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

export default function StudentAnswers({ user_info, whatOpen, whatCourse }) {

  const { existingCourses, existingStudents, existingCategories } = useContext(CoursesContext);
  //items
  const [answStatesList, setAnswStatesList] = useState([]);
  const [flagStatesList, setFlagStatesList] = useState([]);

   //     set search query to empty string
  const [searchQuery, setSearchQuery] = useState("");
   //     set search parameters

  //new2023
  const [searchBy, setSearchBy] = useState('Course Name'); //Va Name
  const [filteredCategory, setFilteredCategory] = useState(0);

  const toggleBlock = (e) => {
    let btns = document.querySelectorAll(".show-btn");
    let blocks = document.querySelectorAll(".answ");
    btns.forEach((btn) => {
      btn.classList.remove("active");
    });
    blocks.forEach((block) => {
      block.classList.remove("active");
    });
    e.target.classList.add("active");
    if (e.target.value === "answers") {
      document.querySelectorAll(".answers-content").forEach((element) => {
        element.classList.add("active");
      });
      document.querySelectorAll(".flags-content").forEach((element) => {
        element.classList.remove("active");
      });
    } else {
      document.querySelectorAll(".flags-content").forEach((element) => {
        element.classList.add("active");
      });
    }
  };

  const splitAnswAndFlags = () => {
    const answSplited = [];
    const flagsSplited = [];

    let newCourses = existingCourses;

    if(searchQuery) {
      newCourses = searchBy === 'Course Name' ? 
        existingCourses.filter(course => course.courseName.toLowerCase().includes(searchQuery.toLowerCase())  ) 
        :
        existingCourses;
    }

    if(filteredCategory && filteredCategory!== 0) newCourses = newCourses.filter(course => course.categoryIds.includes(filteredCategory));
    
    newCourses.forEach(course => {
      if (!course.chapters) return;
      course.chapters.forEach(chapter => {
        if (!chapter.tests) return;
        chapter.tests.forEach(test => {
          if (!test.questions) return;
          if (!test.question_states) return;           
          test.question_states.filter(state => (!user_info || state.studentId === user_info.id )).forEach(state => {
            if(state.reviewed === true) return;
            let filteredUser = user_info;
            if (!filteredUser) filteredUser = existingStudents.find(user => user.id === state.studentId);
            const question = test.questions.find(q => q.id === state.questionId);
            const data = {filteredUser, course, chapter, test, question, state}
            if (question.isAmerican){
              flagsSplited.push(data)
            }else{
              answSplited.push(data);
            }
          })
        })
      })
    })
    //new2023
    setAnswStatesList(
      searchQuery && searchBy === 'Va Name' ? 
      answSplited.filter(answ=>answ.filteredUser.fullName.toLowerCase().includes(searchQuery.toLowerCase()))
      : answSplited
    );
    setFlagStatesList(
      searchQuery && searchBy === 'Va Name' ? 
      flagsSplited.filter(flag=>flag.filteredUser.fullName.toLowerCase().includes(searchQuery.toLowerCase()))
      : flagsSplited
    );
  }
  
  useEffect(() => {
      splitAnswAndFlags();
  }, []);

  useEffect(() => {
      splitAnswAndFlags();
  }, [existingCourses, existingStudents, searchQuery, filteredCategory]);

  console.log(existingCategories)
  return (
    <div className="answers-container">
      <h2 className="title">{whatOpen === "flags" ? "Flags" : "Answers"}</h2>
      {whatOpen !== "answers" && whatOpen !== "flags" && (
        <>
          <div className="options">
            <div className="switch-toggle-btns" id="switch-toggle-btns">
              <button
                className="show-btn active"
                value="answers"
                onClick={toggleBlock}
              >
                <img src={messageIcon} /> {answStatesList.length} Open Ended Answers
              </button>
              <button className="show-btn" value="flags" onClick={toggleBlock}>
                <img src={flagIcon} /> {flagStatesList.length} Flags
              </button>
            </div>
            <div className="search-wrapper">
              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label">Search By</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={searchBy}
                  onChange={(e)=>{setSearchQuery(''); setSearchBy(e.target.value)}}
                  label="Search By"
                >
                  <MenuItem value={'Course Name'}>Course Name</MenuItem>
                  <MenuItem value={'Va Name'}>Va Name</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label={`Enter ${searchBy}`}
                variant="outlined"
                className="search-input"
                type="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                    endAdornment: (
                    <InputAdornment>
                        <IconButton>
                        <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                    )
                }}
              />
            </div>
          </div>
          <div className="search-wrapper cats">
            <FormControl variant="outlined" className='category-wrapper'>
              <InputLabel id="demo-simple-select-outlined-cats">Filter By Category</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-cats"
                id="demo-simple-select-outlined-cats"
                value={filteredCategory}
                onChange={(e)=>setFilteredCategory(e.target.value)}
                label="Filter By Category"
              >
                <MenuItem value={0}>All</MenuItem>
                {existingCategories?.map((cat, key) => {
                  return <MenuItem key={key} value={cat.id}>{cat.categoryName}</MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
            <>
              <Answers states={answStatesList} splitAnswAndFlags={splitAnswAndFlags}/>
              <Flags className="answers-content answ" states={flagStatesList} splitAnswAndFlags={splitAnswAndFlags}/>
            </>    
        </>
      )}

      {whatOpen === "answers" && <Answers states={answStatesList} whatCourse={whatCourse} splitAnswAndFlags={splitAnswAndFlags}/>}
      {whatOpen === "flags" && 
        <Flags
          className="answers-content answ"
          states={flagStatesList}
          isActive={true}
          whatCourse={whatCourse}
        />
      }
    </div>
  );
}
