import { Box, Button, CircularProgress, Modal, TextField } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import LoginContext from "./LoginContext";
import CloseIcon from '@material-ui/icons/Close';
import { userForgotPassword } from "../../services/users_api";
import AppContext from "../App/AppContext";
import { handlingAfterFetch } from "../Helpers/ToolsComponents";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const {setAlertObject} = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [mailSend, setMailSend] = useState("SEND");
    const [isLoading, setIsLoading] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSendMail = async () => { 
        setIsLoading(true);
        handlingAfterFetch(       
        await userForgotPassword({email}), 
            (result) => {
                console.log(result);
                if(result.success && result.message){
                    setIsLoading(false);
                    setMailSend(result.message);
                } else{
                    setIsLoading(false);
                    setMailSend('error occured, please contact support');
                }
               
            },
        setAlertObject
        );
    }

    useEffect(() => {  
        
    }, [])

    return (
        <>
        <a className="primary-brand" href="#" onClick={handleOpen}> Forgot Password </a>
            <div className="popup">  
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="forgot-modal"
                >
                    <Box sx={style}>
                        <Button className="close-modal-btn" onClick={handleClose}>
                            <CloseIcon/>
                        </Button>
                        <h3>Enter Your Email</h3>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="Email"
                            autoFocus
                            value={email} 
                            onChange={(e) => {e.preventDefault(); setEmail(e.target.value);}}
                        />
                        {isLoading && <CircularProgress/>}
                        <Button variant="contained" className={`${mailSend.toLowerCase() === 'email sent' ? 'system-success-bckg' : 'primary-brand-bckg'}`} onClick={handleSendMail} disabled={mailSend.toLowerCase() === 'email sent' ? true : false}>{mailSend}</Button>
                    </Box>
                </Modal>
            </div>
        </>
    );
}
