import React, { useState, useContext, useEffect } from "react";
import {Button, Backdrop, Modal, Fade, Box, TextField, TextareaAutosize, FormControl} from '@material-ui/core';


function EditQuestionAnswer({answerData, postActionHandler}) {

    const [answerContent, setAnswerContent] = useState(answerData.answer);

    const updateAnswer = () => {
        const dataForUpdating = {...answerData, answer: answerContent};
        postActionHandler(dataForUpdating); 
    }

    useEffect(() => {
         updateAnswer();
     }, [answerContent]);

    return (
        
        <TextField 
            //defaultValue={answerData.isCorrect ? "Enter correct answer" : "Enter incorrect answer"}  
            defaultValue={answerContent}
            label={answerData.isCorrect ? "correct" : "incorrect"}
            className={answerData.isCorrect ? "success" : "error"} 
            onChange = {(e) => setAnswerContent(e.target.value)}
        />
        
    )
}

export default EditQuestionAnswer;