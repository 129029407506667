import React, { useEffect, useState, useContext } from "react";
import messageIcon from "../../assets/images/messageIcon.png"
import flagIcon from "../../assets/images/flagIcon.png"
import { Button } from "@material-ui/core";
import EditCourse from "./EditCourse";
import no_image from '../../assets/images/no-image.png';
import { SERVER_ENDPOINT } from "../../services";
import CoursesContext from "./CoursesContext";
import AppContext from "../App/AppContext";
import {handlingAfterFetch} from '../Helpers/ToolsComponents';
import { deleteCourse} from "../../services/courses_api";
import { WARNING_ALERT_OBJECT } from "../Helpers/AlertPopup";

function CourseBlock({courseData, postActionHandler, progressInfo}){
    const {existingCourses, setExistingCourses, existingStudents} = useContext(CoursesContext); 
    const {loggedInUser, setAlertObject, setDialogObject} = useContext(AppContext);
    const [answStatesList, setAnswStatesList] = useState([]);
    const [flagStatesList, setFlagStatesList] = useState([]);

    const splitAnswAndFlags = () => {
        let user_info;
        const answSplited = [];
        const flagsSplited = [];    
         
        
        if (!courseData.chapters) return;
        courseData.chapters.forEach(chapter => {
            if (!chapter.tests) return;
            chapter.tests.forEach(test => {
                if (!test.questions) return;
                if (!test.question_states) return;           
                test.question_states.filter(state => (!user_info || state.studentId === user_info.id )).forEach(state => {
                    if(state.reviewed === true) return;
                    let filteredUser = user_info;
                    if (!filteredUser) filteredUser = existingStudents.find(user => user.id === state.studentId);
                    const question = test.questions.find(q => q.id === state.questionId);
                    const data = {filteredUser, courseData, chapter, test, question, state}
                    if (question.isAmerican){
                    flagsSplited.push(data)
                    }else{
                    answSplited.push(data);
                    }
                })
            })
        })
        
        
        setAnswStatesList(answSplited);
        setFlagStatesList(flagsSplited);
    }
    
    useEffect(() => {
        splitAnswAndFlags();
    }, []);

    useEffect(() => {
        splitAnswAndFlags();
    }, [courseData, existingStudents]);

    const doDeleteCourse = async () => {
        await handlingAfterFetch(
            deleteCourse(courseData.id), 
            (result) => {
                setExistingCourses(
                    [...existingCourses.filter(d => (d.id !== courseData.id))]
                );
                setAlertObject({...WARNING_ALERT_OBJECT, 
                    severity:'success',
                    message: `Course <${courseData.courseName}> has been deleted successfully!
                    Close this popup to continue...`
                });
            },
            setAlertObject
        ); 
    }

    const handlerDeleteCourse = (e) => {
        e.preventDefault();
        const deleteCourseConfig = {
            open:true, 
            ok:"DELETE", cancel:"CANCEL",
            title:`Delete course: ${courseData.courseName}?`, 
            description: "Please, review and press DELETE to remove this Course or CANCEL to decline the action.",
            content: null,
            call_on_submit: doDeleteCourse
        };
        setDialogObject(deleteCourseConfig);        
    } 

    const courseImgUrl = courseData.courseImageUrl ? `${SERVER_ENDPOINT}${courseData.courseImageUrl}` : no_image;

    return(
        <div className="course-block">
            <div className="title">
                <div>
                    <img src={courseImgUrl} />
                </div>
                <h4 className="name">{courseData.courseName}</h4>
            </div>
            <p className="count">{courseData && Array.isArray(courseData.students) ? courseData.students.length : 0} VA's On Course</p>
            <ul className="chapters">
                {     
                    courseData?.chapters.map((chapter, key) => {
                       //addProgressInfo      
                        return <li className="chapter" id={chapter.id} key={key}>Chapter {key+1}: {chapter.chapterName}</li>
                    })   
                }
            </ul>
            <div className="info">
                <div className="info-item">
                    <img src={messageIcon} alt="messageIcon"/>
                    <p> {answStatesList.length} Answers</p>
                </div>
                <div className="info-item">
                    <img src={flagIcon} alt="flagIcon"/>
                    <p> {flagStatesList.length} Flags</p>
                </div>
            </div>
            <div className="btns">
                <EditCourse courseData={courseData} postActionHandler={postActionHandler}> EDIT </EditCourse>
                <Button variant="contained" onClick={handlerDeleteCourse} className="button primary-disabled-bckg system-black" > DELETE</Button>
            </div>
        </div>
    )
}

export default CourseBlock;