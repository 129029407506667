import React, { useContext } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {Button} from '@material-ui/core';
//import {deleteLessonFile} from "../../services/courses_api";
import { WARNING_ALERT_OBJECT } from '../Helpers/AlertPopup';
import AppContext from '../App/AppContext';

function LessonFileBlock({fileData, deleteFileInList}) {
    const {setAlertObject} = useContext(AppContext);

    // const handleDeleteFile = () => {
    //     deleteLessonFile(fileData.id);
    //     setAlertObject({...WARNING_ALERT_OBJECT, 
    //         severity:'success',
    //         message: `File < ${fileData.fileName} > has been deleted!
    //         But any changes will be applied after course saving!`
    //     });
    // }

    const doDeleteFile = () => {
        deleteFileInList(fileData);
        setAlertObject({...WARNING_ALERT_OBJECT, 
            severity:'success',
            message: `File < ${fileData.fileName} > has been deleted!
            But any changes will be applied after course saving!`
        });
    }

    return (
        <div className="file">
            <p>{fileData.fileName}</p>
            <Button onClick = {doDeleteFile}><DeleteIcon/></Button>
        </div>
    )
}

export default LessonFileBlock;