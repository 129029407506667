import React, { useState, useContext , useEffect} from "react";
import {handlingAfterFetch} from '../Helpers/ToolsComponents';
import {getCategoriesOfAllUsers} from "../../services/users_api";
import AppContext from "../App/AppContext"; 
import { Button, Modal, Fade, Box, Backdrop, TextField, InputAdornment, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { SERVER_ENDPOINT } from "../../services";
import user from '../../assets/images/user.png';
import CoursesContext from "./CoursesContext";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '60%',
    height: '60vh',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

function StudentAssignedToCourses({manageCourseStudents, courseStudents, courseName, catIds}) {

    const {existingStudents} = useContext(CoursesContext);  
    const {setAlertObject} = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [toggledUsers, setToggledUsers] = useState(courseStudents ? [...courseStudents] : []);
    const [searchQuery, setSearchQuery] = useState("");
    const [allCetegoriesOfAllUsers, setAllCetegoriesOfAllUsers] = useState([]); 
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const toggleAssignStudent = (studentData, buttonId) => {  
        const el = document.getElementById(buttonId);
        let updatedList = [...toggledUsers];
        if(el){
            el.classList.toggle("active");
            if (el.classList.contains("active")) {
                if (!updatedList.includes(studentData.id)) updatedList.push(studentData.id);
                el.innerHTML = "Assigned";
            }else{
                updatedList = updatedList.filter(studentId => studentId !== studentData.id);
                el.innerHTML = "Assign";
            }
            setToggledUsers(updatedList);
        }
    }
 
    const assignStudents = () => {
        manageCourseStudents(toggledUsers);
        handleClose();
    }

    const assignUsersByCourseCat = () => {

        const updatedToggledUsers = [...toggledUsers];
        existingStudents.forEach((studentData) => {
            let isUserAssigned = toggledUsers.includes(studentData.id);
            if (!isUserAssigned && allCetegoriesOfAllUsers.length > 0 && catIds){
                //if user is not toggle let check: probably we should auto-toggle him
                const foundSameCatForUserAndCourse = catIds.find(courseCatId => {
                    return allCetegoriesOfAllUsers.find(userCat => (userCat.categoryId === courseCatId && userCat.userId === studentData.id));
                })
                if (foundSameCatForUserAndCourse) {
                    updatedToggledUsers.push(studentData.id);
                }
            }
        })
        if (updatedToggledUsers.length !== toggledUsers.length) setToggledUsers(updatedToggledUsers);
    }

    const fetchData = async () => {
        await handlingAfterFetch(
            getCategoriesOfAllUsers(), 
            (result) => {
                setAllCetegoriesOfAllUsers(result.data);
            },
            setAlertObject  
        );      
    };

    const buildStudentsTable = () => {
        let usersForShow = existingStudents;
        const lcSearchQuery = searchQuery.toLowerCase();
        if (lcSearchQuery){
            usersForShow = usersForShow.filter(stud => stud.fullName.toLowerCase().includes(lcSearchQuery));
        }        
        
        return  usersForShow.map((studentData, key) => {
            let isUserAssigned = toggledUsers.includes(studentData.id);
            const id=`item${key}`;
            return (
                <div key={key} className="assistant-block" >
                    <div className="ass-info">
                        <img src={studentData.userImageURL ? `${SERVER_ENDPOINT}${studentData.userImageURL}` : user}/>
                        <p>{studentData.fullName}</p>
                    </div>
                    <Button 
                        variant="contained" 
                        className={`assign-btn ${isUserAssigned ? 'active': ''}`} 
                        id={id} 
                        onClick={() => toggleAssignStudent(studentData, id)}>
                            {isUserAssigned ? 'Assigned': 'Assign'}
                    </Button>
                </div>
            );
        })
    }

    useEffect(() => {
        fetchData();
      }, []);
    
    useEffect(() => {
        assignUsersByCourseCat();
    }, [existingStudents, catIds, allCetegoriesOfAllUsers])

    return (
        <>
            <Button variant="contained" className="add-btn primary-brand-bckg system-black" onClick={handleOpen}>+ </Button>
            <p>Assign VA's to course</p>
            <div className="modal">  
                <Modal
                    className="modal-add-ass"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>  
                            <Button className="close-modal-btn" onClick={handleClose}>Back</Button>
                            <div className="container">
                            <TextField
                                    label="Search VA by Name"
                                    variant="outlined"
                                    className="search-input"
                                    type="search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                            <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                />
                                <div className="all-assistants">
                                    { buildStudentsTable() } 
                                </div>
                                <Button 
                                    className="assign-accept-btn primary-brand-bckg system-black" 
                                    //сноска 1 !!!
                                    onClick={assignStudents}>Assign VA's to {courseName ? courseName : "course"}
                                </Button>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}

export default StudentAssignedToCourses;
