import React, {useContext, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import LoginContext from './LoginContext';
import { Button, FormControl, InputLabel } from '@material-ui/core';

const EmailPassword = ({user_info}) => {
    const {email, setEmail, password, setPassword} = useContext(LoginContext);
    const [newUserPass, setNewUserPass] = useState(false);
    const [values, setValues] = React.useState({
        showPassword: false
      });

    const handleClickShowPassword = () => {
    setValues({
        ...values,
        showPassword: !values.showPassword,
    });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeUserPass = () => {
        setNewUserPass(!newUserPass);
    }

    return (
        <div className="fields">
            <TextField
                type={'email'}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="off"
                autoFocus
                defaultValue={email} 
                onChange={(e) => {e.preventDefault(); setEmail(e.target.value);}}
            />
            {user_info && 
                <>
                    <Button className="primary-disabled-bckg" onClick={handleChangeUserPass}>change password</Button>
                    {newUserPass &&
                        <FormControl variant="outlined"> 
                            <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
                            <OutlinedInput
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={values.showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                defaultValue={password} 
                                onChange={(e) => {e.preventDefault(); setPassword(e.target.value);}}
                                endAdornment={
                                    <InputAdornment>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    }
                </>
            }{!user_info &&  
                <>
                    <FormControl variant="outlined"> 
                        <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
                        <OutlinedInput
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={values.showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            defaultValue={password} 
                            onChange={(e) => {e.preventDefault(); setPassword(e.target.value);}}
                            endAdornment={
                                <InputAdornment>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </>
            }
        </div>
    );
}

export default EmailPassword;