import {fetchGet, fetchPost, fetchDelete, fetchPut, SERVER_ENDPOINT} from './index'


export const fetchExistingPresentations = async () => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/presentations`, "Getting presentation data");
} 

export const fetchExistingPresentationCategories = async () => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/presentations/categories`, "Getting presentation category data");
} 

export const addPresentationCategory = async (category_data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/presentations/categories`, category_data, 'application/json', "Adding presentation category");
} 

export const createPresentation = async (presentation_data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/presentations`, presentation_data, 'application/json', "Adding presentation")    
}

export const deletePresentation = async (id) => {
    return await fetchDelete(`${SERVER_ENDPOINT}/api/presentations/${id}`, {}, 'application/json', "Deleting presentation")    
}

export const deleteCategory = async (id) => {
    return await fetchDelete(`${SERVER_ENDPOINT}/api/presentations/categories/${id}`, {}, 'application/json', "Deleting category")    
}

export const updatePresentation = async (presentation_data) => {
    return await fetchPut(
        `${SERVER_ENDPOINT}/api/presentations/${presentation_data.id}`, 
        presentation_data, 
        'application/json', "Updating presentation")    
}

export const updateCategory = async (category_data) => {
    return await fetchPut(
        `${SERVER_ENDPOINT}/api/presentations/categories/${category_data.id}`, 
        category_data, 
        'application/json', "Updating category")    
} 


export const createSlideInPresentation = async (presentationId, slide_data) => {
    return await fetchPost(
        `${SERVER_ENDPOINT}/api/presentations/${presentationId}/slides`, 
            slide_data, 
            'application/json', 
        "Linking a slide to a presentation")    
}

export const fetchAllSlidesOfPresentation = async (id) => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/presentations/${id}/slides`, "Fetching slides of presentation")    
}

export const deleteSlide = async (id) => {
    return await fetchDelete(`${SERVER_ENDPOINT}/api/presentations/slides/${id}`, 
    {}, 'application/json', "Deleting slide")    
}

export const updateSlide = async (slide_data) => {
    return await fetchPut(
        `${SERVER_ENDPOINT}/api/presentations/slides/${slide_data.id}`, 
        slide_data, 
        'application/json', "Updating slide")    
}