import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {ERORR_ALERT_OBJECT, WARNING_ALERT_OBJECT} from './AlertPopup'

export const getLabelValue = (label, value, label_variant="h5", value_variant="h5", text_color="#000000") => {
    return (
     <Grid container spacing={1} alignItems="center"
     justifyContent="center">
       <Grid item>
         <Typography variant={label_variant} color="textSecondary" align="justify" style={{color: text_color}}>
           {label}
         </Typography>
       </Grid>
       {value!==null && value!==undefined && 
          <Grid item>
            <Typography variant={value_variant} color="textPrimary" align="justify" style={{color: text_color}}>
              {value}
            </Typography>
          </Grid>     
       }
     </Grid>
  );
 }

 export const handlingAfterFetch = async (fetch, success_handler, setAlertObject) => {
  try {
      const result = await fetch;

      if(result.status === 200){
          if(result.success) {
            if (success_handler) success_handler(result);
          } else {
              setAlertObject({...ERORR_ALERT_OBJECT, message: result.message});
          }
      }else if (result.status === 401){
          // unautorized
          setAlertObject({...WARNING_ALERT_OBJECT, severity:'warning', message: result.message})
      }else{
          setAlertObject({...ERORR_ALERT_OBJECT, message: result.message})
      }
  } catch (error) {
      setAlertObject({...ERORR_ALERT_OBJECT, message: `Unexpected error: ${error}`})
  }
}