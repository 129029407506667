import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Fade, Modal, Backdrop, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import user from '../../assets/images/user.png';
import RepeatIcon from '@material-ui/icons/Repeat';
import CheckIcon from '@material-ui/icons/Check';
import MailIcon from '@material-ui/icons/Mail';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { SERVER_ENDPOINT } from "../../services";
import { setQuestionStateReviewedInDB, deleteQuestionState, getStudentInCourseState, getQuestionStateForStudent, updateStudentInCourseStateInDB } from "../../services/states_api";
import { SUCCESS_ALERT_OBJECT } from "../Helpers/AlertPopup";
import { handlingAfterFetch } from "../Helpers/ToolsComponents";
import AppContext from "../App/AppContext";
import { sendTestReplyToStudent } from "../../services/users_api";
import CoursesContext from "./CoursesContext";
import { fetchExistingCourses } from "../../services/courses_api";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '50%',
    height: '80vh',
    bgcolor: '#FEFEFF',
    border: 'none', 
    borderRadius: '40px !important',
    p: 4,
};

function ResolveAnswer({studentInfo, question, studentAnswer, answers, state, course, chapter, test}) {
    const [open, setOpen] = useState(false);
    const [reply, setReply] = useState("");
    const [mailSent, setMailSent] = useState(false);
    const {setAlertObject} = useContext(AppContext);  
    const {fetchData}  = useContext(CoursesContext);  

    const handleOpen = () => setOpen(true);
    
    const handleClose = () => setOpen(false);

    const handleSolveState = async (e) => {
        let stateData = {
            "studentId": state.studentId, 
            "questionId": state.questionId
        }
        if(e.target.value === "allow"){
            await handlingAfterFetch(
                setQuestionStateReviewedInDB(stateData), 
                (result) => {
                    setAlertObject({...SUCCESS_ALERT_OBJECT, 
                        message: `You successfully allowed to continue to ${studentInfo.fullName}.
                        Close this popup to continue...`
                    });
                    handleClose();
                    // refreshCouresesAnswers();
                    fetchData();
                },
                setAlertObject
            );
                      
        }else if(e.target.value === "disallow"){
            await handlingAfterFetch(
                deleteQuestionState(stateData), 
                (result) => {
                    setAlertObject({...SUCCESS_ALERT_OBJECT, 
                        message: `You successfully returned ${studentInfo.fullName} to repeat test "${test.testName}".
                        Close this popup to continue...`
                    });
                    handleClose();
                    // refreshCouresesAnswers();
                    fetchData();
                },
                setAlertObject
            );
        }
    }

    const handleSendMailToStudent = async (e) => {     
        let stateData = {
            "studentId": studentInfo.id, 
            "studentName": studentInfo.fullName,
            "studentMail": studentInfo.email,
            "courseName": course.courseName,
            "testName": test.testName,
            "question": question,
            "studentAnswer": state.answer,
            "teacherReply": reply
        }

        await handlingAfterFetch(
            sendTestReplyToStudent(stateData), 
            (result) => {
                if(result.success){
                    setMailSent(true);
                }
                setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    message: `You successfully send your reply to ${studentInfo.fullName}.`
                });
            },
            setAlertObject
        );
    }

    return (
        <>
            <Button className="check-btn resolve" onClick={handleOpen}><PlaylistAddCheckIcon/> Review</Button>  
            <Modal
                className="modal-add-reply"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>  
                        <Button onClick={handleClose} className="close-btn"> <CloseIcon/> </Button>
                        <div className="container">
                            <div className="student-info">
                                <img src={studentInfo && studentInfo.userImageURL ? SERVER_ENDPOINT+studentInfo.userImageURL : user}/>
                                <h5>{studentInfo ? studentInfo.fullName : ""}</h5>
                            </div>
                            <div className="answer-block">
                                <div className="course-info">
                                    <p>{course.courseName}</p>
                                    <p>{chapter.chapterName} / {test.testName}</p>
                                </div>
                                <h4 className="question">{question}</h4>
                                <div className="answer">
                                    {studentAnswer}
                                </div>
                                {answers && 
                                    <div className="answers">
                                        {answers.map((answ)=>{
                                            return(
                                                <div className={answ.isCorrect ? "correct": ""} key="">
                                                    <p>{answ.answer}</p>                                                 
                                                    <span>{(state.whatTry > 1) && (state.answer==answ.id) ? `${state.whatTry} try` : ""}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                            <div className="reply-block">
                                <p>Reply to VA: </p>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Type your answer here"
                                    multiline
                                    rows={4}
                                    defaultValue={reply}
                                    onChange={(e) => {setReply(e.target.value)}}
                                    variant="outlined"
                                />
                            </div>
                            {reply ? <Button className={mailSent ? 'mail-to-assistant disabled' : 'mail-to-assistant'} disabled={mailSent} onClick={(e) => {handleSendMailToStudent(e)}}> <MailIcon/> Mail this Feedback to VA</Button> : ''}    
                            <div className="action-btns">
                                <button className="disallow" value="disallow" onClick={(e) => {handleSolveState(e)}}> <RepeatIcon/> Send VA to Repeat Test</button>
                                <button className="allow" value="allow" onClick={(e) => {handleSolveState(e)}}> <CheckIcon/> Allow VA to Continue</button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default ResolveAnswer;
