import React, {useContext} from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import AppContext from '../App/AppContext'

export const ERORR_ALERT_OBJECT = {duration:20000, open:true, message:'', severity: 'error', call_after_close:null};
export const WARNING_ALERT_OBJECT = {duration:6000, open:true, message:'', severity: 'warning', call_after_close:null};
export const SUCCESS_ALERT_OBJECT = {duration:3000, open:true, message:'', severity: 'success', call_after_close:null};
export const INFO_ALERT_OBJECT = {duration:6000, open:true, message:'', severity: 'info', call_after_close:null};
export const HIDDEN_ALERT_OBJECT = {...SUCCESS_ALERT_OBJECT, open:false, call_after_close:null};

export const AlertPopup = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const AlertPopupContainer = (props) => {
    const {alertObject, setAlertObject} = useContext(AppContext);
    
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') { return; }
        const {call_after_close} = alertObject;
        setAlertObject({...alertObject, open: false});
        if (call_after_close) { call_after_close(); }
    };

    return (
        <Snackbar open={alertObject.open} autoHideDuration={alertObject.duration} onClose={handleCloseAlert}>
            <AlertPopup onClose={handleCloseAlert} {...alertObject}>
                {alertObject.message}
            </AlertPopup>
        </Snackbar>  
    );
}

export default AlertPopupContainer;
