import React, { useState, useContext } from "react";
import DeleteIcon  from '@material-ui/icons/Delete';
import {Button, TextField} from '@material-ui/core';
import AppContext from '../App/AppContext';
import CoursesContext from './CoursesContext';
import {handlingAfterFetch} from '../Helpers/ToolsComponents'
import { deleteCategory } from "../../services/courses_api";
import {WARNING_ALERT_OBJECT} from '../Helpers/AlertPopup';
import { updateCategory } from "../../services/courses_api";
import {SUCCESS_ALERT_OBJECT} from '../Helpers/AlertPopup';

function CourseCategoryBlock(props) {
    const [category, setCategory] = useState(props.catData.categoryName);
    const {setAlertObject, setDialogObject} = useContext(AppContext);   
    const {existingCategories, setExistingCategories} = useContext(CoursesContext);

    const changeCategoryName = (e) => {
        setCategory(e.target.value);
    }

    const handleSaveCategory = async (e) => {
        e.preventDefault();
        await handlingAfterFetch(
            updateCategory({id:props.catData.id, category: category}), 
            (result) => {
                console.log('update_cat:', result);
                const updatedCategory = result.data;
                const updatedList = existingCategories.map(s => (s.id === updatedCategory.id ? updatedCategory : s));
                setExistingCategories([...updatedList])
                setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    message: `Category <${updatedCategory.categoryName}> has been updated successfully!`
                });
            },
            setAlertObject
        );
    } 

    const doDeleteCategory = async (cat) => {
  
        await handlingAfterFetch(
            deleteCategory(props.catData.id), 
            (result) => {
                setExistingCategories(
                    [...existingCategories.filter(d => (d.id !== props.catData.id))]
                );
                setAlertObject({...WARNING_ALERT_OBJECT, 
                    severity:'success',
                    message: `Category <${props.catData.categoryName}> has been deleted successfully!
                    Close this popup to continue...`
                });
            },
            setAlertObject
        ); 
    } 

    const handlerDeleteCategory = (e) => {
        e.preventDefault();
        if(props.catData.id === 1) {
            return; // спроси папу
        }
        else {
            const deleteCategoryConfig = {
                open:true, 
                ok:"DELETE", cancel:"CANCEL",
                title:`Delete category: ${props.catData.categoryName}?`, 
                description: "Please, review and press DELETE to remove this category or CANCEL to decline the action.",
                content: null,
                call_on_submit: doDeleteCategory
            };  
            setDialogObject(deleteCategoryConfig);   
        }
    }

    return( 
        <div className="catBlock" key={props.catData.id}>
            <TextField defaultValue={props.catData.categoryName} onBlur={(e)=>changeCategoryName(e)}></TextField>
            <div className="buttonsGroup">
                <Button key={props.catData.id} variant="contained" onClick={handleSaveCategory} className="primary-brand-bckg black-system"> Save </Button>
                <Button key={props.catData.id} onClick={handlerDeleteCategory}><DeleteIcon/></Button>
            </div>
        </div>
    )
}

export default CourseCategoryBlock;