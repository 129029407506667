import React, { useState, useContext } from "react";
import {FormControl, InputLabel, Input, Modal, Button, Box} from '@material-ui/core';
import AppContext from '../App/AppContext';
import {SUCCESS_ALERT_OBJECT} from '../Helpers/AlertPopup'
import {handlingAfterFetch} from '../Helpers/ToolsComponents'
import { addCourseCategory } from "../../services/courses_api";
import CloseIcon from '@material-ui/icons/Close';
import CoursesContext from "./CoursesContext";
import CourseCategoryBlock from "./CourseCategoryBlock";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CourseCategoriesPopup(props) { 
    const {setAlertObject} = useContext(AppContext);  
    const {existingCategories, setExistingCategories} = useContext(CoursesContext);   

    const [open, setOpen] = useState(false);
    const [categoryName, setCategoryName] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const addCategoryToList = (d) => {
        setExistingCategories([...existingCategories, d]);
    }

    const clearCategoryData = ()=>{
        setCategoryName("");
    }

    const handlerAddCategory = async (e) => {
        e.preventDefault();
        await handlingAfterFetch(
            addCourseCategory({categoryName}), 
            (result) => {
                addCategoryToList(result.data);
                setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    message: `Category <${categoryName}> has been added successfully!
                    Close this popup to continue...`,
                    call_after_close: clearCategoryData
                });
            },
            setAlertObject
        ); 
    }

    return(
        <>
            <Button variant="contained" className="button MuiButtonBase-root MuiButton-root MuiButton-contained primary-brand-bckg system-black" onClick={handleOpen}> + Add Course Category </Button>
            <div className="popup">  
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Button className="close-modal-btn" onClick={handleClose}>
                            <CloseIcon/>
                        </Button>
                        <FormControl>
                            <div className="input-wrapper">
                                <InputLabel htmlFor="name-input">Add New Category</InputLabel>
                                <Input id="name-input" aria-describedby="my-helper-text"  onChange={(e) => {setCategoryName(e.target.value)}} />
                            </div>
                            <Button variant="contained" className="primary-brand-bckg black-system" disable={categoryName? false: true} onClick={handlerAddCategory} > Add Category </Button>
                        </FormControl>
                        <h3>My Categories</h3>
                        <div className="categories-container">
                        {
                             existingCategories.map((cat) =>  
                                <CourseCategoryBlock catData={cat} key={cat.id}/>
                                ) 
                        }
                        </div>
                    </Box>
                </Modal>
            </div>
        </> 
    )
}

export default CourseCategoriesPopup;