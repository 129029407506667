import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import EmailPassword from './EmailPassword'
import LoginContext from './LoginContext'
import '../../assets/scss/login.scss'
import logo from '../../assets/images/Logo.png';
import ForgotPassword from './ForgotPassword';
import { Link } from 'react-router-dom';
import {ReactComponent as TheGuy} from '../../assets/images/logo_TheGuy.svg'

const UserSignIn = ({handleSubmitSignIn}) => {

    return (
        <div className="login-container">
            <Container component="main" className="login-box">
                <div className="logo"> <img src={logo} alt="Logo"/> </div>
                <Typography component="h1">
                    VAA Learning System
                </Typography>
                <p className="quote">
                    “Live as if you were to die tomorrow. Learn as if you were to live forever.”
                </p>
                <p className="quote"> – Mahatma Gandhi </p>
                <form onSubmit={handleSubmitSignIn} className="form-control">
                    <EmailPassword />  
                    <div className="btn-wrapper"> 
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="primary-brand-bckg black-system"
                        >
                            Log In
                        </Button>
                        <ForgotPassword/>
                    </div>                  
                </form>
            </Container>
            <Link to={{pathname: "https://www.theguy.co.il/en"}} target="_blank" className="theguy_logo">
               <TheGuy/>
            </Link>
        </div>
    );
}

export default UserSignIn;