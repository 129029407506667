import React, { useState, useContext, useEffect } from "react";
import {Button, Backdrop, Modal, Fade, Box, TextField, TextareaAutosize, FormControl, FormControlLabel, FormGroup, Checkbox} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Header from "../Helpers/Header";
import EditQuestionAnswer from "./EditQuestionAnswer";
import AppContext from "../App/AppContext";
import { WARNING_ALERT_OBJECT } from "../Helpers/AlertPopup";


const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

function EditTestQuestion({questionData, postActionHandler, deleteQuestionInList}) {
    const {setAlertObject, setDialogObject} = useContext(AppContext);

    const currentDate = new Date();
    const DEFAULT_SET_OF_ANSWERS = [];
    for (let index = 0; index < 4; index++) {
        const answerCreatedAt = new Date(currentDate);
        currentDate.setSeconds(currentDate.getSeconds() + 1);
        const newAnswer = {
            isCorrect: index == 0 ? 1 : 0,
            createdAt: ''+answerCreatedAt,
            answer: ""
        };   
        DEFAULT_SET_OF_ANSWERS.push(newAnswer)     ;
    }

    const [questionContent, setQuestionContent] = useState(questionData.questionContent);
    const [isAmerican, setIsAmerican] = useState(((questionData.isAmerican === 1 || questionData.isAmerican === true) ? true : false));
    const [existingAnswers, setExistingAnswers] = useState((questionData.answers && questionData.answers.length > 0 ? questionData.answers : DEFAULT_SET_OF_ANSWERS));

    const doDeleteQuestion = () => {
        deleteQuestionInList(questionData);
        setAlertObject({...WARNING_ALERT_OBJECT, 
            severity:'success',
            message: `Question <${questionContent.substring(0,20)}...> has been deleted successfully!
            But any changes will be applied after course saving!`
        });
    }

    const handleDeleteQuestion = (e) => {
        e.preventDefault();
        const deleteQuestionConfig = {
            open:true, 
            ok:"DELETE", cancel:"CANCEL",
            title:`Delete question: ${questionContent.substring(0,20)}...?`, 
            description: "Please, review and press DELETE to remove this Question or CANCEL to decline the action.",
            content: null,
            call_on_submit: doDeleteQuestion
        };
        setDialogObject(deleteQuestionConfig);         
    }

    const updateQuestion = () => {
        const dataForUpdating = {...questionData,
            questionContent,
            isAmerican: isAmerican ? 1: 0,
            answers: isAmerican ? existingAnswers : []
        };
        postActionHandler(dataForUpdating); 
    }

    useEffect(() => {
        updateQuestion();
    }, [questionContent, isAmerican, existingAnswers]);

    const updateAnswerInList = (d) => {
        let updatedList = [...existingAnswers];
        updatedList = updatedList.map(answer => {
            if (d.createdAt === answer.createdAt) return d;
            return answer;
        });
        setExistingAnswers(updatedList);
    }

    return (
        <div className="test-question"> 
            
            <FormControl component="fieldset">
                <div className="heading">
                    <p> Please, Enter Question </p>
                    <Button className="system-black" onClick={handleDeleteQuestion}><DeleteIcon/></Button>  
                </div>
                <TextareaAutosize 
                    label="Question" 
                    className="question-area"
                    onChange={(e) => {setQuestionContent(e.target.value);}}
                    aria-label="empty textarea"
                    style={{ height: '200px' }}
                    defaultValue={questionContent}
                />
                <FormGroup>
                    <FormControlLabel control={<Checkbox color="primary" onChange={(e) => {setIsAmerican(e.target.checked)}} defaultChecked={isAmerican}/>} label="This is an american question" />
                </FormGroup>
                {isAmerican && <div className="answers">
                    <p> Please, Enter one correct and three incorrect answers </p>
                    {
                        existingAnswers.map((answerData, index) => { 
                            return (
                                <EditQuestionAnswer
                                    key={index}
                                    answerData={answerData}
                                    postActionHandler={updateAnswerInList}
                                />
                            )
                        })
                    }
                </div>}
            </FormControl>
        </div>
    )
}

export default EditTestQuestion;