import { Button, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import React, { useState, useContext , useEffect} from "react";
import AppContext from "../App/AppContext";
import CoursesContext from "./CoursesContext";

export default function ShowTestQuestion({questionData, questionState, index}) {
    const {loggedInUser} = useContext(AppContext); 
    const {upsertQuestionState} = useContext(CoursesContext); 
    const correctAnswer = questionData.answers.find(ans => ans.isCorrect);

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [answers, setAnswers] = useState(questionData.answers && questionData.answers.sort(() => Math.random() - Math.random()).map((answer, key)=>{return answer}));
    const [answer, setAnswer] = useState(questionState.hasOwnProperty("answer") ? (''+questionState.answer).trim() : "");
    const [whatTry, setWhatTry] = useState(questionState.hasOwnProperty("whatTry") ? Number(questionState.whatTry) : 0);
    const [status, setStatus] = useState(questionState.hasOwnProperty("status") && questionState.status?  1  : 0);
    const [reviewed, setReviewed] = useState(questionState.hasOwnProperty("reviewed") && questionState.reviewed ? 1 : 0);

    const handleChangeValue = (event) => {
        setAnswer(event.target.value);
    };

    const checkAnswer = (e) => {
        const currentTry = whatTry+1;
        if (questionData.isAmerican){
            if ((''+correctAnswer.id) === answer){
                document.getElementById(answer).classList.add("success");
                setStatus(1);
                if (currentTry === 1)  setReviewed(1);
            }else{
                document.getElementById(answer).classList.add("error");
            }            
        }else{
            setStatus(1);            
        }
        setWhatTry(currentTry);
    }

    const setButtonText = () => {
        const checkButtonEl = document.getElementById(`check-button${index}`);
        if (whatTry > 0 ){
            if (questionData.isAmerican){
                if (status > 0){
                    checkButtonEl.innerHTML = "Success";
                    checkButtonEl.style = "pointer-events:none";
                }else{
                    checkButtonEl.innerHTML = "Check Again";
                }            
            }else{
                checkButtonEl.style = "pointer-events:none";
                if (reviewed > 0){
                    checkButtonEl.innerHTML = "Your answer is reviewed";
                }else{
                    checkButtonEl.innerHTML = "Pending TM's reviewing...";
                }
            }
        }
    }

    useEffect(() => {
        setButtonText();
        setLoadingStatus(false);
        setAnswers(questionData.answers && questionData.answers.sort(() => Math.random() - Math.random()).map((answer, key)=>{return answer}))
    }, [questionData])

    useEffect(() => {
        if (!loadingStatus){
            setButtonText();
            const question_state = {
                studentId: loggedInUser.id,
                questionId: questionData.id,
                answer, whatTry, status, reviewed
            }
            upsertQuestionState(question_state);
        }
    }, [whatTry])

    return (
        <div className="question-content">
            <p className="question-counter">Question {index}</p>
            <h4>{questionData && questionData.questionContent}</h4>
            {questionData.isAmerican && 
            <div className="answers">
                <RadioGroup aria-label="answers" name="answers" value={answer} onChange={handleChangeValue}>
                    {
                        answers.map((ans, key)=>{
                            return (
                                <div key={key} className="answer">
                                    <FormControlLabel 
                                        value={''+ans.id} 
                                        id={''+ans.id} 
                                        className={whatTry > 0 && (''+ans.id) === answer ? (status > 0 ? "success" : "error") : ""}
                                        control={<Radio color="primary"/>} 
                                        label={ans.answer} 
                                    />
                                </div>
                            )
                        })
                    }
                </RadioGroup>
                
            </div>
            }
            {!questionData.isAmerican && 
            <div className="openEnded">
                <TextField
                    id="outlined-multiline-static"
                    label="Type your answer here"
                    multiline
                    rows={5}
                    defaultValue={answer}
                    onChange={handleChangeValue}
                    variant="outlined"
                />
            </div>
            }
            <Button 
                variant="outlined" 
                id={`check-button${index}`} 
                className="primary-brand-bg" 
                onClick={checkAnswer}
            >Check</Button>
        </div>
    )
}
