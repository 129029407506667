import React, {useState, useContext} from 'react';
import {
    Switch,
    Route,
    Redirect,
    useHistory
  } from "react-router-dom";

import UserSignUp from './User/UserSignUp'
import UserSignIn from './User/UserSignIn'
import UserLogout from './User/UserLogout'
import LoginContext from './User/LoginContext'
import AppContext from './App/AppContext'
import {userSignIn} from '../services/users_api'
import {handlingAfterFetch} from './Helpers/ToolsComponents'
import { PERMISSIONS_ADMIN } from './User';


const ScreenLogin = () => {
    const {loggedInUser, setLoggedInUser, setAlertObject} = useContext(AppContext);
    const {fullName, setFullName, email, setEmail, password, setPassword, permissions, setPermissions, cleanUserData} = useContext(LoginContext);

    const history = useHistory();
    
    const handleSubmitSignIn = async (e) => {
        if (e) { e.preventDefault(); }        
        await handlingAfterFetch(
            userSignIn({username:email, password}), 
            (result) => {
                setLoggedInUser(result.data);      
                history.push("/");        
            }, 
            setAlertObject,
            cleanUserData()
        );
    }
       
    const handleRootPathOnLogIn = () => {
        if (loggedInUser == null) { return null; }
        if (!loggedInUser.email){
            return <Redirect to="/auth/" />
        }
        return null;
    }

    const handleAuthPathOnLogIn = () => {
        if (loggedInUser == null) { return null; }
        if (loggedInUser.email){
            return <Redirect to="/" />
        }
        return <UserSignIn handleSubmitSignIn={handleSubmitSignIn}/>;
    }

    const handleAuthRegisterPathOnLogIn = () => {
        if (loggedInUser == null) { return null; }
        if (loggedInUser.permissions !== PERMISSIONS_ADMIN){
            return <Redirect to="/" />
        }
        return <UserSignUp />;
    }

    const handleAuthLogoutPathOnLogIn = () => {
        if (loggedInUser == null) { return null; }
        if (!loggedInUser.email){
            return <Redirect to="/auth/" />
        }
        return <UserLogout />;
    }

    return (
        <div>
            <Switch>
                <Route exact path="/">
                    {handleRootPathOnLogIn}
                </Route>
                <Route exact path="/auth/">
                    {handleAuthPathOnLogIn}
                </Route>
                {/* <Route exact path="/auth/register">
                    {handleAuthRegisterPathOnLogIn}
                </Route> */}
                <Route exact path="/auth/logout">
                    {handleAuthLogoutPathOnLogIn}
                </Route>
                <Route>
                    {handleRootPathOnLogIn}
                </Route>
            </Switch>
        </div>
    );
}

export default ScreenLogin;