import React, {useEffect, useContext, useState} from "react";
import PresentationEdit from "./PresentationEdit";
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon  from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AppContext from '../App/AppContext';
import PresentationsContext from './PresentationsContext';
import {WARNING_ALERT_OBJECT} from '../Helpers/AlertPopup';
import {handlingAfterFetch} from '../Helpers/ToolsComponents';
import { deletePresentation } from "../../services/presentations_api";
import { Modal, Box} from '@material-ui/core';
import { fetchAllSlidesOfPresentation } from "../../services/presentations_api";
import { SERVER_ENDPOINT } from "../../services";
import no_image from '../../assets/images/no-image.png'
import { PERMISSIONS_ADMIN, PERMISSIONS_STUDENT, PERMISSIONS_TEACHER } from "../User";
import PresentationView from "./PresentationView";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: "80vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function PresentationBlock({presentationData}) {

    const {loggedInUser, setAlertObject, setDialogObject} = useContext(AppContext);       
    const {existingPresentations, setExistingPresentations} = useContext(PresentationsContext);   
    
    
    const [presentationUrl, setPresentationUrl] = useState(null);
    const [open, setOpen] = useState(false); //modal
    const [existingSlides, setExistingSlides] = useState([]);


    const handleOpen = () => setOpen(true); //modal
    const handleClose = () => setOpen(false); //modal

    const doDeletePresentation = async () => {
        await handlingAfterFetch(
            deletePresentation(presentationData.id), 
            (result) => {
                setExistingPresentations(
                    [...existingPresentations.filter(d => (d.id !== presentationData.id))]
                );
                setAlertObject({...WARNING_ALERT_OBJECT, 
                    severity:'success',
                    message: `Presentation <${presentationData.name}> has been deleted successfully!
                    Close this popup to continue...`
                });
            },
            setAlertObject
        );
    }

    const handlerDeletePresentation = (e) => {
        e.preventDefault();
        const deletePresentationConfig = {
            open:true, 
            ok:"DELETE", cancel:"CANCEL",
            title:`Delete presentation: ${presentationData.name}?`, 
            description: "Please, review and press DELETE to remove this presentation or CANCEL to decline the action.",
            content: null,
            call_on_submit: doDeletePresentation
        };
        setDialogObject(deletePresentationConfig);        
    } 

    useEffect(() => {
        const fetchData = async () => {
            handlingAfterFetch(
                fetchAllSlidesOfPresentation(presentationData.id), 
                (result) => {
                    if (Array.isArray(result.data) && result.data.length > 0){
                        setExistingSlides(result.data);                       
                    }
                },
                setAlertObject
            );
        };
        fetchData();        
    }, [presentationData, setAlertObject]) 

    useEffect(() => {
        if (Array.isArray(existingSlides) && existingSlides.length > 0){
            setPresentationUrl(`${SERVER_ENDPOINT}${existingSlides[0].imgUrl}`);
        }else{
            setPresentationUrl(null);
        }        
    }, [existingSlides]) 

    const handlerPermissionsRelatedModal = () => {
        if (loggedInUser.permissions === PERMISSIONS_ADMIN || loggedInUser.permissions === PERMISSIONS_TEACHER){
            return  <PresentationEdit 
                presentationData={presentationData}
                existingPresentations={existingPresentations} 
                setExistingPresentations={setExistingPresentations}
                existingSlides={existingSlides} 
                setExistingSlides={setExistingSlides}
                closePresentationEdit={handleClose}/>
        }else {
            return <PresentationView existingSlides={existingSlides}/>
        }
    }

    return (
        <div className="item-block">
            {loggedInUser.permissions === PERMISSIONS_ADMIN && <div className="buttons-group">
                <Button onClick={handlerDeletePresentation}>
                    <DeleteIcon/>
                </Button>
            </div>}
            <div className="img-wrapper" id="pres-img-wrapper" onClick={handleOpen}>
                <div className="bigPlus"><VisibilityIcon/></div>
                <img src={presentationUrl ? presentationUrl : no_image} alt={presentationData.name}/>
            </div>
            <div id="presentation-title" className="presentation-title">
                {presentationData.name}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={loggedInUser.permissions === PERMISSIONS_ADMIN ? "ad-app" : "stud-app"}
            >
                <Box sx={style}>
                    <Button className="close-modal-btn" onClick={handleClose}>
                        <CloseIcon/>
                    </Button>
                   {handlerPermissionsRelatedModal()}
                </Box>
            </Modal>
        </div>
    )
}

export default PresentationBlock;
