import React, { useState, useContext, useEffect } from "react";
import {Button, Backdrop, Modal, Fade, Box, TextField, TextareaAutosize, FormControl} from '@material-ui/core';
import Header from "../Helpers/Header";
import EditTestQuestion from "./EditTestQuestion";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AppContext from "../App/AppContext";
import { WARNING_ALERT_OBJECT } from "../Helpers/AlertPopup";


const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

function EditTest({testData, chapterData, courseName, postActionHandler, deleteTestInList}) {
    const {setAlertObject, setDialogObject} = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const [testName, setTestName] = useState((testData && testData.testName)? testData.testName: '');
    const [existingQuestions, setExistingQuestions] = useState(testData?.questions ? testData.questions : []);

    const handleOpen = () => setOpen(true);    
    const handleClose = () => setOpen(false);



    const doDeleteTest = () => {
        deleteTestInList(testData);
        setAlertObject({...WARNING_ALERT_OBJECT, 
            severity:'success',
            message: `Test <${testName}> has been deleted successfully!
            But any changes will be applied after course saving!`
        });
    }

    const handleDeleteTest = (e) => {
        e.preventDefault();
        const deleteTestConfig = {
            open:true, 
            ok:"DELETE", cancel:"CANCEL",
            title:`Delete test: ${testName}?`, 
            description: "Please, review and press DELETE to remove this Test or CANCEL to decline the action.",
            content: null,
            call_on_submit: doDeleteTest
        };
        setDialogObject(deleteTestConfig);         
    }

    const handleTestUpsertion = () => {

        const dataForUpdating =  {                
                testName,
                questions: existingQuestions
            };
        if (testData) {
            dataForUpdating.createdAt = testData.createdAt;
        }
        postActionHandler(dataForUpdating); 
        handleClose();
    }

    const addQuestion = (type) => {
        const newQuestion = {questionContent: "", isAmerican:0, createdAt: ''+new Date()};
        setExistingQuestions([...existingQuestions, newQuestion]);
    }

    const updateQuestionInList = (d) => {
        let updatedList = [...existingQuestions];
        updatedList = updatedList.map(question => { 
            if (d.createdAt === question.createdAt) return d;
            return question;
        });
        setExistingQuestions(updatedList);
    }

    const deleteQuestionInList = (d) => {       
        let updatedList = [...existingQuestions];
        updatedList = updatedList.filter(question => (d.createdAt !== question.createdAt));
        setExistingQuestions(updatedList);
    };

    return (
        <>
            <Button variant={testData ? "" : "contained"} className={testData ? "system-black" : "secondary-disabled-bckg system-black"} onClick={handleOpen}>{testData? <EditIcon/> : " + Add Test " }</Button> 
            {testData && <Button className="system-black" onClick={handleDeleteTest}><DeleteIcon/></Button>}
            <div className="modal">  
                <Modal
                    className="modal-add-test"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}> 
                            <Header/> 
                            <div className="container">
                                <h3>{courseName}</h3>
                                <h4>{chapterData && chapterData.chapterName } </h4> 
                                <TextField className="test-name-input" label="Test Name" defaultValue={testName} onChange={(e) => {setTestName(e.target.value)}} />
                                {existingQuestions.map((questionData, index) => 
                                    <EditTestQuestion
                                        key={index} 
                                        questionData={questionData} 
                                        postActionHandler={updateQuestionInList}
                                        deleteQuestionInList={deleteQuestionInList}/>
                                )}
                                <Button 
                                    className="secondary-disabled-bckg system-black" 
                                    onClick={() => addQuestion()}>Add Question</Button>
                                <div className="buttons-bottom">
                                    <Button variant="contained" className="primary-disabled-bckg system-black" onClick={handleClose}> DISCARD </Button>
                                    <Button variant="contained" className="primary-brand-bckg system-black" onClick={handleTestUpsertion}> SAVE </Button>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}

export default EditTest;