import React, {useState, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ScreenLogin from '../ScreenLogin';
import ScreenPresentations from '../ScreenPresentations';
import AppContext from './AppContext';
import LoginContext from '../User/LoginContext';
import AlertPopupContainer, {HIDDEN_ALERT_OBJECT, ERORR_ALERT_OBJECT} from '../Helpers/AlertPopup';
import DialogPopupContainer, {HIDDEN_DIALOG_OBJECT} from '../Helpers/DialogPopup';
import {fetchVerifiedUser} from '../../services/users_api';
import {PERMISSIONS_ADMIN, PERMISSIONS_TEACHER} from '../User/index';
import ScreenPresentationsForStudents from '../ScreenPresentationsForStudents';
import ScreenHomeForAdmin from '../ScreenHomeForAdmin';
import ScreenHomeForStudents from '../ScreenHomeForStudents';
import { handlingAfterFetch } from '../Helpers/ToolsComponents';
import logo from '../../assets/images/Logo.png';
import '../../assets/scss/theme.scss'
import {uploadFile} from '../../services/common_api';

function App() {
  
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [currentScreen, setCurrentScreen] = useState("");
  const [alertObject, setAlertObject] = useState(HIDDEN_ALERT_OBJECT);
  const [dialogObject, setDialogObject] = useState(HIDDEN_DIALOG_OBJECT);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [permissions, setPermissions] = useState("");
  const [createdAt] = useState(null);
  const [updatedAt] = useState(null);

  const cleanUserData = () => {
    setEmail("");
    setPassword("");
    setPermissions("");
    setFullName("");
  }

  const verifyUserSessionIsActive = async () => {    
    try {
      const verifiedUser = await fetchVerifiedUser();
      
      if (verifiedUser.success && verifiedUser.data.email){
        verifiedUser.data.isAdmin = (verifiedUser.data.permissions.toLowerCase() === PERMISSIONS_ADMIN);
        setLoggedInUser(verifiedUser.data);
        return;
      } else if (verifiedUser.status !== 200){
        setAlertObject({...ERORR_ALERT_OBJECT, message: verifiedUser.message});        
      }
      setLoggedInUser({});
    } catch (error) {
      
        setAlertObject({...ERORR_ALERT_OBJECT, message: `Unexpected error in verifying user session: ${error}`})
    }
  };

  useEffect(() => {
    verifyUserSessionIsActive();
  }, []);
  
  const renderAnAppropriateScreen = () => {
    if (!loggedInUser || Object.keys(loggedInUser).length === 0){ 
      return <ScreenLogin />;
    }
    //return <Redirect to="/presentations/" />
    return <Redirect to="/learning-system/home/" />
  }

  const renderPresentations = () => {
    if (!loggedInUser || !loggedInUser.permissions){ 
      return <Redirect to="/" />
    }
    if (loggedInUser.permissions === PERMISSIONS_ADMIN || loggedInUser.permissions === PERMISSIONS_TEACHER){
      return <ScreenPresentations />;    
    }else{
      return <ScreenPresentationsForStudents/>;    
    }
  }

  const renderLearningHome = () => {
    if (!loggedInUser || !loggedInUser.permissions){ 
      return <Redirect to="/" />
    }
    if (loggedInUser.permissions === PERMISSIONS_ADMIN || loggedInUser.permissions === PERMISSIONS_TEACHER){
      return <ScreenHomeForAdmin />;      
    }else{
      return <ScreenHomeForStudents/>; 
    }
  }
  
  const handlerUploadFile = async (file) => {
      let data = null;
      await handlingAfterFetch(            
          uploadFile(file), 
          (result) => {
              data = result.data;
          },
          setAlertObject
      );
      return data;        
  }

  const uploadFirstImageIfProvided = async (imageFiles) => {
      if (imageFiles && imageFiles.length > 0) {
          const image = imageFiles[0];
          //upload image to server
          const imageData = await handlerUploadFile(image.file);
          //create slide
          if (imageData){
              return imageData.fileUrl;
          }    
      }
      return null;
  }

  const appContextData = {
    verifyUserSessionIsActive,
    loggedInUser, setLoggedInUser, 
    currentScreen, setCurrentScreen,
    alertObject, setAlertObject,
    dialogObject, setDialogObject,
    handlerUploadFile, uploadFirstImageIfProvided
  }

  const loginContextData = {
      fullName, setFullName, 
      email, setEmail, 
      password, setPassword, 
      permissions, setPermissions,
      createdAt, 
      updatedAt, 
      cleanUserData
  };
  
    return (
      <>
        {window.innerWidth > 1024 ? 
          <Router> 
            <AppContext.Provider value={appContextData}>
              <LoginContext.Provider value={loginContextData}>
                <div className="App">   
                  <AlertPopupContainer />
                  <DialogPopupContainer />
                  <Switch>
                      <Route exact path="/auth/*">
                        <ScreenLogin/>
                      </Route>
                      <Route exact path="/presentations/*">
                        {renderPresentations()}
                      </Route>
                      <Route exact path="/learning-system/home/">
                        {renderLearningHome()}
                      </Route>
                      <Route>
                        {renderAnAppropriateScreen()}        
                      </Route>
                  </Switch>          
                </div>
              </LoginContext.Provider>
            </AppContext.Provider>
          </Router> 
        : 
          <div className="App responsive">   
            <div className="container">
              <div className="logo"> <img src={logo} alt="Logo"/> </div>
              <h1>OOPS!</h1>
              <h3>Please use larger device</h3>
            </div>        
          </div>
        }
      </>
      
      
    );

}

export default App;
