import React, { useEffect, useState, useContext } from "react";
import no_image from "../../assets/images/no-image.png";
import { SERVER_ENDPOINT } from "../../services";
import { handlingAfterFetch } from "../Helpers/ToolsComponents";
import { fetchExistingCoursesCategories } from "../../services/courses_api";
import AppContext from "../App/AppContext";
import EditChapter from "./EditChapter";
import { Button, LinearProgress } from "@material-ui/core";
import OpenCourse from "./OpenCourse";
import CoursesContext from "./CoursesContext";
import {
  getStudentInCourseState,
  updateStudentInCourseStateInDB,
} from "../../services/states_api";
import CoursesBlockContext from "./CoursesBlockContext";

function CourseBlockStudent({ courseData, finished }) {

  const courseImgUrl = courseData.courseImageUrl
    ? `${SERVER_ENDPOINT}${courseData.courseImageUrl}`
    : no_image;

  const { setAlertObject, loggedInUser } = useContext(AppContext);
  const { existingCategories } = useContext(CoursesContext);

  const [studentInCourseStates, setSudentInCourseStates] = useState([]);
  const [completedInfo, setCompletedInfo] = useState({ items: 0, total: 0 });

  const updateCompletedInfo = (states) => {
    let items = courseData.hasOwnProperty("completed_chapters") ? courseData.completed_chapters: 0;
    let total = courseData.chapters.length;
    setCompletedInfo({ items, total });
  };

  const fetchData = async () => {
    handlingAfterFetch(
      await getStudentInCourseState(courseData.id, loggedInUser.id),
      (response) => {
        updateCompletedInfo(response.data.states);
        setSudentInCourseStates(response.data.states);
      },
      setAlertObject
    );
  };

  useEffect(() => {
    // code to run on component mount
    fetchData();
  }, []);

  useEffect(() => {
    // code to run on component mount
    fetchData();
  }, [courseData]);

  const upsertStudentInCourseState = async (state) => {
    let updated_states = studentInCourseStates.filter(
      (s) => s.chapterId != state.chapterId
    );
    let updated_state = { ...state };
    updated_state.lastModified = new Date();
    updated_states.push(updated_state);
    updated_states.forEach(us => {
      if (typeof(us.completed) !== "string"){
        us.completed = JSON.stringify(us.completed);      
      }
    })          

    await handlingAfterFetch(
      updateStudentInCourseStateInDB({
        courseId: courseData.id,
        studentId: loggedInUser.id,
        states: updated_states,
      }),
      (response) => {
        updateCompletedInfo(response.data.states);
        setSudentInCourseStates(response.data.states);
      },
      setAlertObject
    );
  };

  const getCourseCategories = () => {
    const courseCategories = existingCategories.filter((cat) =>
      courseData.categoryIds.includes(cat.id)
    );
    return courseCategories.map((cat) => cat.categoryName).join(", ");
  };

  const coursesBlockContextData = {
    studentInCourseStates,
    upsertStudentInCourseState,
  };

  return (
    <CoursesBlockContext.Provider value={coursesBlockContextData}>
      <div className="course-block">
        <div className="img-wrapper">
          <img src={courseImgUrl}></img>
        </div>
        <div className="text-wrapper">
          <h3>{courseData.courseName}</h3>
          <h4>{getCourseCategories()}</h4>
          <div className="progress">
            <p className={finished ? "complete" : ""}>
              Completed {completedInfo.items}/{completedInfo.total} chapters
            </p>
            <LinearProgress
              variant="determinate"
              value={(completedInfo.items * 100)/completedInfo.total}
              className={finished ? "complete" : ""}
            />
          </div>
          <OpenCourse courseData={courseData} finished={finished}/>
        </div>
      </div>
    </CoursesBlockContext.Provider>
  );
}

export default CourseBlockStudent;
