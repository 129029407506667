import React, { useEffect, useState, useContext } from "react";
import CoursesContext from "./CoursesContext";
import user_img from "../../assets/images/user.png";
import { SERVER_ENDPOINT } from "../../services";
import ResolveAnswer from "./ResolveAnswer";
import Pagination from '@material-ui/lab/Pagination';
import { Box } from "@material-ui/core";

export default function Flags({states, isActive, whatCourse}) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
  
    const generate_items = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const itemsToDisplay = whatCourse ? states : states.slice(startIndex, endIndex);

        return itemsToDisplay.map(item => {
            const {filteredUser, course, chapter, test, question:q, state} = item;
            if(!whatCourse){
                return (
                    <div className="flag-block" key={state.id}>
                        <div className="student-info">
                        <img
                            src={
                            filteredUser && filteredUser.userImageURL
                                ? SERVER_ENDPOINT +
                                filteredUser.userImageURL
                                : user_img
                            }
                        />
                        <h5 className="student-name">
                            {filteredUser
                            ? filteredUser.fullName
                            : "username"}
                        </h5>
                        </div>
                        <h4 className="question">{q.questionContent}</h4>
                        <div className="course-info">
                        <p className="course-name">{course.courseName}</p>
                        <p className="chapter-name">
                            {chapter.chapterName} / {test.testName}
                        </p>
                        </div>
                        <div className="checked-answers">
                        {q.answers.map((answ) => {
                            return (
                            <div className={answ.isCorrect ? "correct" : ""} key={answ.id}>
                                <p>{answ.answer}</p>
                                <span>
                                {state.whatTry > 1 &&
                                state.answer == answ.id
                                    ? `${state.whatTry} try`
                                    : ""}
                                </span>
                            </div>
                            );
                        })}
                        </div>
                        <ResolveAnswer
                        studentInfo={filteredUser}
                        course={course}
                        chapter={chapter}
                        test={test}
                        question={q.questionContent}
                        answers={q.answers}
                        state={state}
                        />
                    </div>
                );
            }else if(whatCourse === course.courseName){
                return (
                    <div className="flag-block">
                        <div className="student-info">
                        <img
                            src={
                            filteredUser && filteredUser.userImageURL
                                ? SERVER_ENDPOINT +
                                filteredUser.userImageURL
                                : user_img
                            }
                        />
                        <h5 className="student-name">
                            {filteredUser
                            ? filteredUser.fullName
                            : "username"}
                        </h5>
                        </div>
                        <h4 className="question">{q.questionContent}</h4>
                        <div className="course-info">
                        <p className="course-name">{course.courseName}</p>
                        <p className="chapter-name">
                            {chapter.chapterName} / {test.testName}
                        </p>
                        </div>
                        <div className="checked-answers">
                        {q.answers.map((answ) => {
                            return (
                            <div
                                className={answ.isCorrect ? "correct" : ""}
                            >
                                <p>{answ.answer}</p>
                                <span>
                                {state.whatTry > 1 &&
                                state.answer == answ.id
                                    ? `${state.whatTry} try`
                                    : ""}
                                </span>
                            </div>
                            );
                        })}
                        </div>
                        <ResolveAnswer
                        course={course}
                        chapter={chapter}
                        test={test}
                        studentInfo={filteredUser}
                        // courseName={course.courseName}
                        // chapterName={chapter.chapterName}
                        // testName={test.testName}
                        question={q.questionContent}
                        answers={q.answers}
                        state={state}
                        />
                    </div>
                );
            }
            else if(whatCourse === "No Course Assignment"){
                return(
                    <h3 className="title">
                        There is no current flags.
                    </h3>
                )
            }
        })   
    }

    return(
        <>
            <div className={isActive===true ? "flags-content active" : "flags-content"}>
                {generate_items()}
            </div>
            {   isActive===true && !whatCourse &&
                <Box mt={5} mx={'auto'} width={'fit-content'}>
                    <Pagination 
                        count={Math.ceil(states.length/itemsPerPage)} 
                        page={currentPage} size="large" 
                        onChange={(e, value) => setCurrentPage(value)}
                    />
                </Box>
            }
        </>
    )
} 