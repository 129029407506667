import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Fade, Modal, Backdrop, TextField } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import userDefaultImg from '../../assets/images/user.png';
import ResolveAnswer from "./ResolveAnswer";
import Header from "../Helpers/Header";
import {handlingAfterFetch} from '../Helpers/ToolsComponents';
import {fetchExistingUsers} from '../../services/courses_api';
import AppContext from "../App/AppContext";
import { SERVER_ENDPOINT } from "../../services";
import { fetchAllQuestionStates, getAllStudentsInCourseStates } from "../../services/states_api";
import CoursesContext from "./CoursesContext";
import SettingsIcon from '@material-ui/icons/Settings';
import {PERMISSIONS_TEACHER, PERMISSIONS_STUDENT } from '../User';
import ManagmentPanelPopup from "./ManagmentPanelPopup";
import EditUser from "../User/EditUser";


const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

function AssistantManagmentPanel({img, permissions, existingUsers}) {
    const [open, setOpen] = useState(false);
    const {setAlertObject} = useContext(AppContext);
    const {existingCourses} = useContext(CoursesContext);
    const [courseStates, setCourseStates] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const fetchData = async () => {
        handlingAfterFetch(
            getAllStudentsInCourseStates(), 
            (result) => {
                setCourseStates(result.data);                       
            },
            fetchAllQuestionStates(),
            (result) => {
                setCourseStates(result.data);                       
            },
            setAlertObject
        );
    };

    useEffect(() => {
        fetchData();        
    }, []) 

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const buildTable = () => {
        let users;
        if (searchQuery){
            users = existingUsers.filter(user => user.permissions === permissions && user.fullName.toLowerCase().includes(searchQuery.toLowerCase()));
        }else{
            users = existingUsers.filter(user => user.permissions === permissions);
        }
        if (permissions === PERMISSIONS_STUDENT){
            const notExistingState = {
                "courseName": "No Course",
                "chapterName": "No Chapter",
                "stepName": "No Lesson"
            }
            const userStates = users.map(user => {
                const state = {user};          
                state.data = courseStates.filter(cs => cs.studentId === user.id).map(cs => {
                    const exCourse = existingCourses.find(course => course.id === cs.courseId);
                    if (exCourse){
                        const existingState = { 
                            "courseName": exCourse.courseName,
                            "chapterName": "No Chapter Assignment",
                            "stepName": "No Lesson Assignment"
                        }
                        const chapter = exCourse.chapters.find(chap => chap.id === cs.chapterId);
                        if (chapter){
                            existingState.chapterName = chapter.chapterName;
                            if (cs.lessonId != 0){
                                const step = chapter.lessons.find(les => les.id === cs.lessonId);
                                if (step){
                                    existingState.stepName = step.lessonName;
                                }
                            }else if(cs.testId != 0){
                                const step = chapter.tests.find(tes => tes.id === cs.testId);
                                if (step){
                                    existingState.stepName = step.testName;
                                }
                            }
                        }
                        return existingState;

                    }else{
                        return notExistingState;
                    }
                });
                
                if (state.data.length === 0){
                    state.data.push(notExistingState);
                }
                return state;
            })
            return userStates.reduce((all_rows, usSt) => {
                usSt.data.forEach(state => {
                    all_rows.push(
                        <div className="table-row">
                            <div className="cell user-icon"><img src={usSt.user.userImageURL ? SERVER_ENDPOINT+usSt.user.userImageURL : userDefaultImg}/></div>
                            <p className="cell user-name">{usSt.user.fullName}</p>
                            <p className="cell course">{state.courseName}</p>
                            <p className="cell chapter">{state.chapterName}</p>
                            <p className="cell lesson">{state.stepName}</p>
                            <p className="cell current-flags"> <ManagmentPanelPopup user_info={usSt.user} whatCourse={state.courseName} whatOpen="flags"/></p>
                            <p className="cell current-answers"> <ManagmentPanelPopup user_info={usSt.user} whatCourse={state.courseName} whatOpen="answers"/></p>
                            <div className="cell seller">
                                <EditUser user_info={usSt.user} course={state.courseName} userPermissions={PERMISSIONS_STUDENT}/>
                            </div>
                        </div>
                    )
                })
                return all_rows;
            }, [])
        }  
        if (permissions === PERMISSIONS_TEACHER){
            return existingUsers.map(user => {
                return(
                    <div className="table-row" key="">
                        <div className="cell user-icon"><img src={user.userImageURL ? SERVER_ENDPOINT+user.userImageURL : userDefaultImg}/></div>
                        <p className="cell user-name">{user.fullName}</p>
                        <div className="cell seller">
                            <EditUser user_info={user} userPermissions={PERMISSIONS_TEACHER}/>
                        </div>
                    </div>
                )
            })
        }      
    }

    return (
        <>
            <Button variant="contained" className="button primary-brand-bckg system-black" onClick={handleOpen}> <img src={img} alt="icon_ppl"/> Go to {permissions === "student" ? "VA" : "TM"} Managment Panel </Button>
            <div className="modal">  
                <Modal
                    className="modal-managment"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}> 
                            <Header/> 
                            <div className="container">
                                <div className="top-panel">
                                    <Button onClick={handleClose} className="primary-brand-bckg black-system back-btn"> <ArrowBackIosIcon/> Back to Home</Button>
                                    <TextField
                                    label={permissions === "student" ? "Search VA by Name" : "Search TM"}
                                    variant="outlined"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="search-input"
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                            <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    />
                                </div>
                                <div className="table-container">
                                    {permissions==PERMISSIONS_STUDENT &&
                                    <>
                                        <div className="labels">
                                            <div className="user-icon"><img src={userDefaultImg}/></div>
                                            <p className="label user-name">Name</p>
                                            <p className="label course">Current Course</p>
                                            <p className="label chapter">Current Chapter</p>
                                            <p className="label lesson">Current Lesson</p>
                                            <p className="label current-flags">Current Flags</p>
                                            <p className="label current-answers">Current Answers</p>
                                            <p className="label seller">Settings</p>
                                        </div>
                                        <div className="scrollable">
                                            <div className="table-rows">
                                                {buildTable()}
                                            </div>
                                        </div>
                                    </>
                                    }
                                    {permissions==PERMISSIONS_TEACHER &&
                                    <>
                                        <div className="labels tm">
                                            <div className="user-icon"><img src={userDefaultImg}/></div>
                                            <p className="label user-name">Name</p>
                                            <p className="label seller">Settings</p>
                                        </div>
                                        <div className="scrollable">
                                            <div className="table-rows tm">
                                                {buildTable()}
                                            </div>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}

export default AssistantManagmentPanel;
