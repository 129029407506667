import {fetchGet, fetchPost, fetchDelete, fetchPut, SERVER_ENDPOINT} from './index'

export const updateQuestionStateInDB = async (data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/states/question/`, data, 'application/json', "Upsert question state")    
}

export const fetchAllQuestionStates = async () => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/states/question/`, "Getting question states for test");
} 

export const getQuestionStateForStudent = async (studentId) => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/states/question/${studentId}`, studentId, 'application/json', "Update states to user")    
}

export const getStudentInCourseState = async (courseId, studentId) => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/states/student/${studentId}/course/${courseId}`, "Getting state of student in course");    
} 

export const updateStudentInCourseStateInDB = async (data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/states/student/${data.studentId}/course/${data.courseId}`, data, 'application/json', "Upsert state of student in course");
}

export const getAllStudentsInCourseStates = async () => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/states/students/`, "Getting state of student in course");    
} 

export const setQuestionStateReviewedInDB = async (stateData) => {
    return await fetchPut(`${SERVER_ENDPOINT}/api/states/question/${stateData.questionId}`, stateData, 'application/json', "Update states to user")    
}

export const deleteQuestionState = async (stateData) => {
    return await fetchDelete(`${SERVER_ENDPOINT}/api/states/question/${stateData.questionId}`, stateData, 'application/json', "Delete states to user")    
}