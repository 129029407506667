import React from 'react'
import Lottie from 'lottie-react';
import Animation1 from '../../assets/lotties/animation_lmzxsoog.json';
import Animation2 from '../../assets/lotties/rFmR6i7OUa.json';

function CustomPreLoader() {
  return (
    <div className="pre-loader">
        {/* <Lottie
            animationData={Animation1}
            loop={true}
            className='lottie1'
        /> */}
        <Lottie
            animationData={Animation2}
            loop={true}
            className='lottie2'
        />
    </div>
  )
}

export default CustomPreLoader