import React, {useContext, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LoginContext from './LoginContext'
import EmailPassword from './EmailPassword';

const UserAllPropeties = ({userPermissions, user_info}) => {
    const {fullName, setFullName, permissions, setPermissions} = useContext(LoginContext);
    
    useEffect(() => {
        setPermissions(userPermissions ? userPermissions : "");
    }, [])

    return (
        <div className="fields">
            <Grid>        
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="full_name"
                    label="Full name"
                    name="full name"
                    autoComplete="off"
                    autoFocus
                    defaultValue={fullName} 
                    onChange={(e) => {e.preventDefault(); setFullName(e.target.value);}}
                />
            </Grid>
            <EmailPassword user_info={user_info}/>
            <Grid>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="permissions"
                    label="Permissions"
                    name="permissions"
                    autoComplete="Permissions"
                    style={{display: "none"}}
                    value={permissions} 
                    onChange={(e) => {e.preventDefault(); setPermissions(e.target.value);}}
                />
            </Grid>
        </div>
    );
}

export default UserAllPropeties;