import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Navigation, Pagination} from 'swiper';
import { SERVER_ENDPOINT } from "../../services";
import { updateSlide } from "../../services/presentations_api";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss"
import { Button } from "@material-ui/core";
import { BEHAVIOR_GOTO, BEHAVIOR_NUMBERS, BEHAVIOR_TEXT, REGION_BEHAVIORS } from "../../services/regionBehavior";

//SwiperCore.use([Navigation]);

function PresentationView ({existingSlides}) {
    const allSlideNames = existingSlides.map(slide => slide.name);
    const [swiper, setSwiper] = useState(null);

    const goToSlide = (index) => {

        if(swiper) swiper.slideTo(index);
    }

    const num_re = new RegExp(REGION_BEHAVIORS[BEHAVIOR_NUMBERS].regex);

    const prepareAllRegions = (slide) => {
        return slide.regions.map((region) => {
            let top_position = region?.y + "%";
            let left_position = region?.x + "%";
            let button_left_position = region?.x + region?.width + 1 + "%";
       
            const showButtonIfNotEmpty = (e) => {
                e.target.parentNode.lastChild.style.display = e.target.value.trim() ? "block" : "none";
            }

            const handleGoToSlide = (e) => {
                e.preventDefault();    
                goToSlide(allSlideNames.indexOf(region.gotoSlideName));
            }

            if(region.behavior === BEHAVIOR_NUMBERS){

                return(
                    <form className="input-area" action="" onSubmit={handleGoToSlide}>
                        <input 
                            type="text" 
                            pattern={REGION_BEHAVIORS[BEHAVIOR_NUMBERS].regex}
                            onChange={showButtonIfNotEmpty} 
                            style={{fontSize: '20px', fontWeight: '700', position:"absolute", zIndex: "9", top: top_position , left: left_position, width: region?.width + "%" , height: region?.height + "%"}}
                            required 
                        />
                        <input 
                            type="submit" 
                            className="primary-brand-bckg black-system" 
                            value="GO"
                            style={{display: "none", position:"absolute", zIndex: "99", top: top_position , left: button_left_position }}
                        />                           
                    </form>
                )
            }else if(region.behavior == BEHAVIOR_TEXT){
                return(                    
                    <form className="input-area" action="" onSubmit={handleGoToSlide}>
                        <textarea 
                            onChange={showButtonIfNotEmpty}
                            style={{fontSize: '20px', fontWeight: '700', position:"absolute", zIndex: "9", top: top_position , left: left_position, width: region?.width + "%" , height: region?.height + "%", resize: "none"}}
                            required
                        />
                        <input 
                            type="submit" 
                            className="primary-brand-bckg black-system" 
                            value="GO"
                            style={{display: "none", position:"absolute", zIndex: "99", top: top_position , left: button_left_position }}
                        />                           
                    </form>
                )
            }else if(region.behavior == BEHAVIOR_GOTO){
                return(
                    <Button onClick={handleGoToSlide} style={{fontSize: '20px', fontWeight: '700', position:"absolute", zIndex: "9", top: top_position , left: left_position, width: region?.width + "%" , height: region?.height + "%"}}/>
                )
            }
            return null;
        })

    }

    return (
        <div className="slider-container">
            <Swiper 
                //pagination={{"dynamicBullets": true}} 
                navigation={false}
                className="mySwiper"
                onSwiper={setSwiper}
                scrollEnabled = {false}
                allowTouchMove = {false}
            >
            {
                existingSlides.map((slide, index) => {
                    return(
                        <SwiperSlide key={index}>
                            <img src={`${SERVER_ENDPOINT}${slide.imgUrl}`} alt={slide.name} style={{position:"relative"}} />
                            { prepareAllRegions(slide)}                            
                        </SwiperSlide>
                    )
                })
            }
            </Swiper>
        
        </div>
        
    )
}

export default PresentationView;