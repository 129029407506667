import {fetchGet, fetchPost, fetchPut, fetchDelete, SERVER_ENDPOINT} from './index';


export const fetchVerifiedUser = async () => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/auth/verify`, "Verifying user session"); 
};

export const userLogout = async () => {
    return fetchGet(`${SERVER_ENDPOINT}/api/auth/logout`, "User logout");
};

export const userSignIn = async (user_data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/auth/login`, user_data, 'application/json', "User login")    
}

export const userSignUp = async (user_data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/users`, user_data, 'application/json', "User sign-up")    
}

export const userUpdate = async (user_data) => {
    return await fetchPut(`${SERVER_ENDPOINT}/api/users/${user_data.id}`, user_data, 'application/json', "User update")    
}

export const userDelete = async (user_id) => {
    return await fetchDelete(`${SERVER_ENDPOINT}/api/users/${user_id}`, {user_id}, 'application/json', "User delete")    
}

export const getAllUsers = async (permissions) => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/users/${permissions}`, permissions, 'application/json', "get Users")    
}

//my
export const upsertUserSellersInDB = async (seller_data, user_id) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/sellers/${user_id}`, seller_data, 'application/json', "Seller add")    
}

export const getAllAssignedUsers = async (user_data) => {
    //adding to req pasrams permissions user
    return await fetchGet(`${SERVER_ENDPOINT}/api/users/courses/${user_data}`, user_data, 'application/json', "Getting data")    
} 

export const getCourseOfStudent = async (id) => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/users/students/courses/${id}`, id, 'application/json', "Getting data")    
} 

export const getCategoriesOfUser = async (id) => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/users/categories/${id}`, id, 'application/json', "Getting data")    
} 

export const getCategoriesOfAllUsers = async () => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/users/categories/all/`, 'application/json', "Getting data")    
} 

export const addCourseToUser = async (user_data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/users/courses`, user_data, 'application/json', "Add course to assistant/TM")    
} 

export const addCategoryToUser = async (user_data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/users/categories`, user_data, 'application/json', "Add categories to assistant/TM")    
} 

export const updateCategoriesToUser = async (id, checkedCategories) => {
    return await fetchPut(`${SERVER_ENDPOINT}/api/users/categories/${id}`, checkedCategories, 'application/json', "Update categories to user")    
} 

export const userForgotPassword = async (user_data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/forgotpassword`, user_data, 'application/json', "User Forgot Password")    
} 

export const fetchExistingUserSellers = async (id) => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/users/students/sellers/${id}`, id, 'application/json', "Getting data")    
} 

export const sendTestReplyToStudent = async (user_data) => {
    return await fetchPost(`${SERVER_ENDPOINT}/api/testreply`, user_data, 'application/json', "Reply to User")    
}

export const Hello = async () => {
    return await fetchGet(`${SERVER_ENDPOINT}/api/hello`, "Getting data")    
}

