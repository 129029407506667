import React, { useContext, useState } from 'react';
import { Backdrop, Modal, Fade, Box } from "@material-ui/core";
import '../../assets/scss/header.scss';
import AppBar from '@material-ui/core/AppBar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import {getLabelValue} from '../Helpers/ToolsComponents';
import AppContext from '../App/AppContext';
import { PERMISSIONS_ADMIN } from '../User';
import logo from '../../assets/images/Logo.png';
import user from '../../assets/images/user.png';
import { SERVER_ENDPOINT } from "../../services";
import LoginContext from '../User/LoginContext';
import CloseIcon from '@material-ui/icons/Close';
import UserSignUp from '../User/UserSignUp';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "39.3%",
  height: "77vh",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Header(props) {
  const {loggedInUser, currentScreen} = useContext(AppContext);  
  const {setFullName, setEmail} = useContext(LoginContext);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setFullName(loggedInUser.fullName);
    setEmail(loggedInUser.email);
    setOpen(true);
  }

  const handleClose = () => setOpen(false);

  return (
    <>
      <section className="header"> 
        <Box>
          <AppBar position="fixed" className="light-system-bckg header-wrapper">
            <Toolbar>
              <Grid container>
                <Grid className="logo">
                  <Link to="/learning-system/home/"><img src={logo} alt="Logo"/></Link>     
                </Grid>
                <Grid>
                  {getLabelValue(currentScreen, null, "h5", "h5")}          
                </Grid>
                {loggedInUser.permissions === PERMISSIONS_ADMIN && <Grid className="sign-up">
                  {/* <Link to="/auth/register"> Sign Up new user </Link>  */}  
                </Grid>}
                <Grid className="current-user">
                    {getLabelValue("Hello,", `${loggedInUser.fullName}`, "h6", "h6")}  
                    <div className="img-wrapper"><img src={loggedInUser.userImageURL ? SERVER_ENDPOINT+loggedInUser.userImageURL : user} alt="profile-img"/></div>  
                    <div className="hover-menu">
                      {loggedInUser.permissions === PERMISSIONS_ADMIN && <div><Link to="#" onClick={handleOpen}><AccountCircleIcon/>Profile</Link></div>}
                      <div><Link to="/auth/logout"><ExitToAppIcon/>Logout</Link></div>
                    </div>
                </Grid>
                
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      </section>
      {loggedInUser.permissions === PERMISSIONS_ADMIN && 
        <div className="modal">  
            <Modal
                className="modal-managment-popup"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <Box sx={style}> 
                    <CloseIcon onClick={handleClose}/> 
                    <UserSignUp user_info={loggedInUser} userPermissions={loggedInUser.permissions} closer={handleClose}/>
                </Box>
                </Fade>
            </Modal>
        </div>
      }
    </>
  );
}
export default Header;
 

