export const BEHAVIOR_GOTO = "goto";
export const BEHAVIOR_TEXT = "free_text";
export const BEHAVIOR_NUMBERS = "numbers";

export const REGION_BEHAVIORS = {
    [BEHAVIOR_GOTO]:{
        description: "Go to slide on click"
    }, 
    [BEHAVIOR_TEXT]:{
        description: "Enter free text and go to slide"
        /*here could be placed other properties like html, methods, and etc.*/
    }, 
    [BEHAVIOR_NUMBERS]:{
        description: "Enter numbers with dashes and go to slide",
        regex: "^\\d{3}-\\d{7}-\\d{7}$"
        /*here could be placed other properties like html, methods, and etc.*/
    }
};

