import React, {useState, useContext} from "react";
import {createSlideInPresentation} from "../../services/presentations_api";
import AppContext from '../App/AppContext';
import {SUCCESS_ALERT_OBJECT} from '../Helpers/AlertPopup'
import {handlingAfterFetch} from '../Helpers/ToolsComponents'
import { Dropzone, FileItem } from "dropzone-ui";
import {Button} from '@material-ui/core';

function SlideAdd({presentation_id, existingSlides, setExistingSlides}) {
    const {setAlertObject, handlerUploadFile} = useContext(AppContext);       

    const [imageFiles, setImageFiles] = useState([]);
    const [imageSrc, setImageSrc] = useState(undefined);
    const [uploadingStatus, setUploadingStatus] = useState(undefined);

    const handleOnChange = (incommingFiles) => {
        setImageFiles(incommingFiles);
    };
    const handleOnDelete = (id) => {
        setImageFiles(imageFiles.filter((x) => x.id !== id));
    };
    const handleOnSee = (imageSource) => {
        setImageSrc(imageSource);
    };

    const handlerLinkSlideToPresentation = async (imageData, index) => {
        let data = null;
        await handlingAfterFetch(
            createSlideInPresentation(presentation_id, {name: "Unknown"+(existingSlides.length+index), imageUrl: imageData.fileUrl}), 
            (result) => {      
                data = result.data;
                setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    message: `Slide for image <${imageData.originalname}> has been created successfully!`
                });
            },
            setAlertObject
        );
        return data;
    }

    const handleOnUpload = (e) => {
        e.preventDefault();
        const allNewlyAddedSlides = []
        setUploadingStatus(`Uploading ${imageFiles.length} files...`)
        const processFiles = async () => {
            for (let index = 0; index < imageFiles.length; index++) {
                const image = imageFiles[index];
                setUploadingStatus(`Uploading file: ${JSON.stringify(image)}`)
                //upload image to server
                const imageData = await handlerUploadFile(image.file);
                //create slide
                if (imageData){
                    const slideData = await handlerLinkSlideToPresentation(imageData, (index+1));
                    allNewlyAddedSlides.push(slideData);
                }
            }
            setImageFiles([]);
            setUploadingStatus(undefined);
            if (allNewlyAddedSlides.length > 0){
                setExistingSlides([...existingSlides, ...allNewlyAddedSlides]);
                setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    message: `${allNewlyAddedSlides.length} unnamed slides have been linked to the current presentation successfully!`
                });
            }
        }
        processFiles();
    }

    return (
        <div className="slide-container">
            <Dropzone
                onChange={handleOnChange}
                value={imageFiles}
                maxFiles={10}
                maxFileSize={7998000}
                accept={'image/*'}
                >
                {imageFiles.map((file) => (
                    <FileItem key={file.id}
                    {...file} 
                    onDelete={handleOnDelete}
                    onSee={handleOnSee}
                    preview
                    info
                    hd
                    />
                ))}
            </Dropzone>
            {uploadingStatus === undefined && imageFiles.length>0 && 
                <Button 
                    variant="contained" 
                    className="primary-brand-bckg"
                    onClick={handleOnUpload}>Upload selected images </Button>
            }
            {uploadingStatus !== undefined && 
                <p>{uploadingStatus}</p>
            }            
        </div>
       
    );

}

export default SlideAdd;

