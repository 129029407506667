import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";
import "../../assets/scss/login.scss"
import {userLogout} from '../../services/users_api'
import AppContext from '../App/AppContext'
import {ERORR_ALERT_OBJECT, INFO_ALERT_OBJECT} from '../Helpers/AlertPopup'
import {handlingAfterFetch} from '../Helpers/ToolsComponents'

const UserLogout = () => {
    const {setLoggedInUser, setAlertObject} = useContext(AppContext)
    const history = useHistory();
  
    const handleCancelLogOut = async (e) => {
        e.preventDefault();
        history.goBack();
    }

    const handleSubmitLogOut = async (e) => {
        e.preventDefault();
        try {
            const result = await userLogout();
            const success_handler = (result) => {
                // update the loggedInUser-state in the context of App component
                setAlertObject({...INFO_ALERT_OBJECT, 
                    message: `User has been logged out successfully!`
                });
                setLoggedInUser({});   
                history.push("/auth/")              
            };            
            handlingAfterFetch(result, success_handler, setAlertObject);
        } catch (error) {
            setAlertObject({...ERORR_ALERT_OBJECT, message: `Unexpected error: ${error}`})
        }        
    }

    return (
        <div className="logout-container">
            <Typography component="h1" variant="h6">
                Are you sure you want to Log out?
            </Typography>
            <form className="logout-form" onSubmit={handleSubmitLogOut}>
                <Button
                    type="submit"
                    variant="contained"
                    className="primary-brand-bckg "
                >
                    Log Out
                </Button>
                <Button
                    variant="contained"
                    className="primary-disabled-bckg"
                    onClick={handleCancelLogOut}
                >
                    Cancel
                </Button>
            </form>
        </div>
    );
}

export default UserLogout;