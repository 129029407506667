import React, {useContext, useState} from "react";
import PresentationEdit from "./PresentationEdit";
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon  from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import AppContext from '../App/AppContext';
import PresentationsContext from './PresentationsContext';
import {WARNING_ALERT_OBJECT} from '../Helpers/AlertPopup'
import {handlingAfterFetch} from '../Helpers/ToolsComponents'
import { deletePresentation, deleteSlide } from "../../services/presentations_api";
import { Modal, Box, Input} from '@material-ui/core';
import { SERVER_ENDPOINT } from "../../services";
import SlideEdit from './SlideEdit'
import no_image from '../../assets/images/no-image.png'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: "80vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    zIndex: 9
  };

function SlideBlock({ slideData, existingSlides, setExistingSlides}) {

    const [open, setOpen] = useState(false); //modal
    const [name, setName] = useState(slideData.name); //slide name
    const {setAlertObject, setDialogObject} = useContext(AppContext);       

    const handleOpen = () => setOpen(true); //modal
    const handleClose = () => setOpen(false); //modal

    const changeSlideName = (e) => {
        const temp_slides = [...existingSlides];
        const foundedSlide = temp_slides.find(slide => +slide.id === +e.target.id);

        if(foundedSlide){
            foundedSlide.name = e.target.value;
            setExistingSlides(temp_slides);
        }
        setName(e.target.value);
    }  

    const doDeleteSlide = async () => {
        await handlingAfterFetch(
            deleteSlide(slideData.id), 
            (result) => {
                setExistingSlides(
                    [...existingSlides.filter(d => (d.id !== slideData.id))]
                );
                setAlertObject({...WARNING_ALERT_OBJECT, 
                    severity:'success',
                    message: `Slide <${slideData.name}> has been deleted successfully!
                    Close this popup to continue...`
                });
            },
            setAlertObject
        );
    }

    const handlerDeleteSlide = (e) => {
        e.preventDefault();
        const deleteSlideConfig = {
            open:true, 
            ok:"DELETE", cancel:"CANCEL",
            title:`Delete slide: ${slideData.name}?`, 
            description: "Please, review and press DELETE to remove this slide or CANCEL to decline the action.",
            content: <SlideBlock slideData={slideData}/>,
            call_on_submit: doDeleteSlide
        };
        setDialogObject(deleteSlideConfig);        
    } 

    return (
        <div className="item-block">
            {existingSlides!==undefined &&
                <div> 
                    <div className="buttons-group">
                        <Button onClick={handlerDeleteSlide}>
                            <DeleteIcon/>
                        </Button>            
                    </div>
                    <div className="img-wrapper" id="pres-img-wrapper" onClick={handleOpen}>
                        <div className="bigPlus"><VisibilityIcon/></div>
                        <img src={slideData.imgUrl ?`${SERVER_ENDPOINT}${slideData.imgUrl}` : no_image} alt={slideData.name} width="50" height="60"/>
                    </div>
                    <div id="slide-title" className="slide-title">
                        <Input defaultValue={name} variant="standard" id={slideData.id} onChange={(e)=>changeSlideName(e)} />
                    </div>
                </div>
            }            
                        
            {existingSlides != undefined &&
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="edit-slide-modal"
                >
                    <Box sx={style}>
                        <Button className="close-modal-btn" onClick={handleClose}>
                            <CloseIcon/>
                        </Button> 
                        <SlideEdit 
                            slideData={slideData} 
                            existingSlides={existingSlides} 
                            setExistingSlides={setExistingSlides}
                            closeSlideEdit={handleClose}
                            changeSlideName={changeSlideName}
                            name={name}
                        />
                    </Box>
                </Modal>
            }            
        </div>
    )
}

export default SlideBlock;
