import React, {useState, useEffect, useContext} from "react";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {Input, Button, TextField, Select, MenuItem, InputLabel} from '@material-ui/core';
import AppContext from '../App/AppContext';
import {ERORR_ALERT_OBJECT, SUCCESS_ALERT_OBJECT, WARNING_ALERT_OBJECT} from '../Helpers/AlertPopup';
import {handlingAfterFetch} from '../Helpers/ToolsComponents';
import RegionSelect from 'react-region-select';
import { updateSlide } from "../../services/presentations_api";
import { SERVER_ENDPOINT } from "../../services";
import { REGION_BEHAVIORS, BEHAVIOR_GOTO } from "../../services/regionBehavior";

const PurpleSwitch = withStyles({
    switchBase: {
      color: "#3954B0",
      '&$checked': {
        color: "#3954B0",
      },
      '&$checked + $track': {
        backgroundColor: "#99B0E2",
      },
    },
    checked: {},
    track: {},
  })(Switch);

function SlideEdit({slideData, existingSlides, setExistingSlides, closeSlideEdit, changeSlideName, name}) {
    const {setAlertObject} = useContext(AppContext);       
    
    const [regions, setRegions] = useState(
        slideData.regions ? slideData.regions.map((region, index) => { 
            return {...region, 
                data:{
                    index, 
                    isActive:false, 
                    gotoSlideName: (region.gotoSlideName ? region.gotoSlideName : ""), 
                    behavior: (region.behavior ? region.behavior : BEHAVIOR_GOTO)}}
        }) : []
    );

    const removeEmptyRegions = () => {
        return regions.filter(region => (region.width !== 0 && region.height !== 0));
    }

    const handlerOnChangeRegions = (newRegions) => {
        setRegions(newRegions);
    }  

    const myRegionRenderer = (regionProps) => {
        if (regionProps.width === 0 && regionProps.height === 0) return null;
        
        const handleToggleRegion = (e) => {
            const updated_regions = removeEmptyRegions().map(region => {
                if (region.data === regionProps.data) {
                    const updated_region = {...region};
                    updated_region.data.isActive = !updated_region.data.isActive;
                    return updated_region;
                }
                return region;
            })
            setRegions(updated_regions);
        };

        const handleDeleteRegion = (e) => {
            const updated_regions = removeEmptyRegions().filter(region => (region.data !== regionProps.data));
            setRegions(updated_regions);
        }

        const updateRegionData = (property, value) => {
            const updated_regions = removeEmptyRegions().map(region => {
                if (region.data === regionProps.data) {
                    const updated_region = {...region};
                    updated_region.data[property] = value;
                    return updated_region;
                }
                return region;
            })
            setRegions(updated_regions);
        }

        const handleOnNameChange = (e) => {
            updateRegionData("gotoSlideName", e.target.value);
        }

        const handleOnBehaviorChange = (e) => {
            updateRegionData("behavior", e.target.value);
        }

        if (!regionProps.isChanging) {
			return (
                <div className="Check">
                    <div style={{ position: 'absolute', right: 0, bottom: '100%', background: '#fff', border: '1px solid #000'}}>
                        <Button style={{minWidth: "fit-content"}} onClick={handleToggleRegion}> <EditIcon/> </Button>
                        <Button style={{minWidth: "fit-content"}} onClick={handleDeleteRegion}> <DeleteIcon/> </Button>
                    </div>
                    <div className={regionProps.data.isActive === true ? "small-modal open" : "small-modal"}>
                        {/* <TextField
                            id="outlined-helperText"
                            label="Name of slide to navigate"
                            defaultValue=""
                            variant="outlined"
                            onChange={handleOnNameChange}
                            value={regionProps.data.gotoSlideName}
                        /> */}
                        <InputLabel id="demo-simple-select-label1">Name of slide to navigate</InputLabel>
                        <Select
                            id="outlined-helperText"
                            label="Name of slide to navigate"
                            labelId="demo-simple-select-label1"
                            onChange={handleOnNameChange}
                            value={regionProps.data.gotoSlideName}
                            style={{width:'200px'}}
                        >
                            {
                                existingSlides.length > 0 && existingSlides.map((slide, index)=>(
                                    <MenuItem key={index} value={slide.name}>{slide.name}</MenuItem>
                                ))
                            }
                        </Select>
                        <div className="options">
                            <InputLabel id="simple-select-outlined-label"> Region behavior: </InputLabel>
                            <Select
                                value={regionProps.data.behavior ? regionProps.data.behavior : BEHAVIOR_GOTO}
                                label="Region Behavior"
                                labelId="simple-select-outlined-label"
                                onChange={handleOnBehaviorChange}
                            >
                            {
                                Object.keys(REGION_BEHAVIORS).map((behavior_key, index) =>  {
                                    return <MenuItem key={index} value={behavior_key}>{REGION_BEHAVIORS[behavior_key].description}</MenuItem>;
                                }) 
                            }  
                            </Select>  
                        </div>
                    </div>
                </div>
			);
		}
    }

    const handleSaveSlide = async (e) => {
        e.preventDefault();
        //control all regions have not empty slide names for navigation
        const regionsWithEmptySlideNames = removeEmptyRegions().filter(region => (!region.data.gotoSlideName));
        if (regionsWithEmptySlideNames.length > 0){            
            regionsWithEmptySlideNames.forEach(region => {
                region.data.isActive = true;
            });
            setAlertObject({...WARNING_ALERT_OBJECT, 
                message: `Found ${regionsWithEmptySlideNames.length} region(s) with empty slide names for navigation!\nPlease, fix or remove such regions!`});
            return;
        }
        //if we here then everything is OK
        const new_regions = removeEmptyRegions().map((region, index) => {
            return {
                x: region.x, 
                y:region.y, 
                width:region.width, 
                height: region.height, 
                behavior: (region.data.behavior ? region.data.behavior : BEHAVIOR_GOTO),
                gotoSlideName: (region.data.gotoSlideName ? region.data.gotoSlideName: "region" + index)
            };
        })        
        
        await handlingAfterFetch(
            updateSlide({id:slideData.id, name, regions: new_regions}), 
            (result) => {
                const updatedSlide = result.data;
                const updatedList = existingSlides.map((slide) => (slide.id === updatedSlide.id ? updatedSlide : slide));
                setExistingSlides([...updatedList])
                setAlertObject({...SUCCESS_ALERT_OBJECT, 
                    message: `Slide <${updatedSlide.name}> has been updated successfully!`
                });
            },
            setAlertObject
        );
        closeSlideEdit();
    }

    return (
        <div className="slide-container">            
            <div className="catName">
                <Input defaultValue={name} variant="standard" id={slideData.id} onChange={(e)=>changeSlideName(e)} />
                <Button 
                    variant="contained" 
                    className="primary-brand-bckg black-system" 
                    disabled={name?false:true} onClick={handleSaveSlide}> Save </Button>
            </div>
            <div className="slideImage">
                <RegionSelect
                    maxRegions={50}
                    regions={regions}
                    onChange={handlerOnChangeRegions}
                    regionRenderer={myRegionRenderer}
                    className="region"
                >
                    
                    <img alt={slideData.name} src={`${SERVER_ENDPOINT}${slideData.imgUrl}`} />
                </RegionSelect>
            </div>
        </div> 
    );

}

export default SlideEdit;

