import { Button, Backdrop, Modal, Fade, Box } from "@material-ui/core";
import React, { useState } from "react";
import StudentAnswers from './StudentAnswers';
import CloseIcon from '@material-ui/icons/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '90%',
    height: '80vh',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

export default function ManagmentPanelPopup({user_info, whatOpen, whatCourse}) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button onClick={handleOpen} className="button current-flags">review</Button>
            <div className="modal">  
                <Modal
                    className="modal-managment-popup"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                    timeout: 500,
                    }}
                >
                    <Fade in={open}>
                    <Box sx={style}>  
                        <Button onClick={handleClose} className="close-btn"><CloseIcon/></Button>
                        <StudentAnswers user_info={user_info} whatOpen={whatOpen} whatCourse={whatCourse}/>
                    </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    )
}
