import React, { useContext, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";

import {
  userSignUp,
  upsertUserSellersInDB,
  addCourseToUser,
  userUpdate,
  addCategoryToUser,
} from "../../services/users_api";
import UserAllPropeties from "./UserAllPropeties";
import LoginContext from "./LoginContext";
import CoursesContext from "../LearningSystem/CoursesContext";
import AppContext from "../App/AppContext";
import {
  ERORR_ALERT_OBJECT,
  SUCCESS_ALERT_OBJECT,
} from "../Helpers/AlertPopup";
import { handlingAfterFetch } from "../Helpers/ToolsComponents";
import "../../assets/scss/register.scss";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Dropzone, FileItem } from "dropzone-ui";
import { PERMISSIONS_STUDENT, PERMISSIONS_TEACHER, PERMISSIONS_ADMIN } from ".";
import { SERVER_ENDPOINT } from "../../services";
import {getCategoriesOfUser, updateCategoriesToUser } from "../../services/users_api";

const UserSignUp = ({userPermissions, handlerOnSubmit, user_info, seller_info, closer}) => {

  const {email,password,fullName,setFullName,setPassword,setEmail,permissions,cleanUserData} = useContext(LoginContext);
  const {existingCourses, existingCategories, existingStudents, existingTeachers } = useContext(CoursesContext);
  const { setLoggedInUser, setAlertObject, uploadFirstImageIfProvided } = useContext(AppContext);
  const [checkedCourses, setCheckedCourses] = useState({});
  const [checkedCategories, setCheckedCategories] = useState({});
  const [imageFiles, setImageFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);
  const [sellerName, setSellerName] = useState("");
  const [sellerEmail, setSellerEmail] = useState("");
  const [existingUserSellers, setExistingUserSellers] = useState(seller_info ? seller_info : []);

  const history = useHistory();

  const [newUserImg, setNewUserImg] = useState(false);

  const fetchData = async () => {
    if(user_info){
      await handlingAfterFetch(
        getCategoriesOfUser(user_info.id), 
        (result) => {
            if(result){
              const updatedCheckedCategories = {};
              result.data.map((cat)=> {
                updatedCheckedCategories[''+cat.categoryId] = true;
                // array[i] the same: Object[key]
              })
              setCheckedCategories(updatedCheckedCategories);                            
            }
        },
        setAlertObject  
      );      
    }
  };

  const gotoPresentation = () => {
    history.push("/presentations");
  };

  const checkSellerEmailUniqueness = () => {
    const allSellerEmails = new Set;
    for (let i = 0; i < existingUserSellers.length; i++) {
      const seller = existingUserSellers[i];
      if (allSellerEmails.has(seller.sellerEmail)){
        setAlertObject({
          ...ERORR_ALERT_OBJECT,
          message: `Seller email is already used: ${seller.sellerEmail}!`,
          call_after_close: null
        });
        return false;
      }
      allSellerEmails.add(seller.sellerEmail);
    }
    return true;
  }

  const checkUserEmailUniqueness = () => {
    if(userPermissions===PERMISSIONS_STUDENT && email){
      let exist = existingStudents?.find(student => student.email === email);
      if(exist) {
        setAlertObject({
          ...ERORR_ALERT_OBJECT,
          message: `VA with email <${email}> already exist!`,
          call_after_close: null
        });
        return false;
      }
    } else if(userPermissions===PERMISSIONS_TEACHER && email) {
      let exist = existingTeachers?.find(teacher => teacher.email === email);
      if(exist) {
        setAlertObject({
          ...ERORR_ALERT_OBJECT,
          message: `TM with email <${email}> already exist!`,
          call_after_close: null
        });
        return false;
      }
    }
    return true;
  }

  const updateUserSellers = async (user_id) => {
    const userSellers = existingUserSellers.map((seller, key) => {
      return {
        studentId: user_id,
        sellerName: seller.sellerName,
        sellerEmail: seller.sellerEmail,
      };
    })
    return upsertUserSellersInDB(userSellers, user_id);  
  }

  const handleSubmitSignUp = async (e) => {

    e.preventDefault();
    if (!checkSellerEmailUniqueness()) {
      return;
    }
    if (!checkUserEmailUniqueness()) {
      return;
    }
    try {
      const dataForUpsertion = {
        fullName,
        email,
        password: password,
        permissions,
      };
      const userImageURL = await uploadFirstImageIfProvided(imageFiles);
      if (userImageURL) dataForUpsertion.userImageURL = userImageURL;
      await handlingAfterFetch(

        userSignUp(dataForUpsertion),
        (result) => {
          updateUserSellers(result.data.id);
          if (checkedCourses) {
            for (const [key] of Object.entries(checkedCourses)) {
              const dataForUpsertionCourse = {
                userId: result.data.id,
                courseId: Number(key),
                userPermissions,
              };
              addCourseToUser(dataForUpsertionCourse);
            }
          }
          if (checkedCategories) {
            for (const [key] of Object.entries(checkedCategories)) {
              const dataForUpsertionUserCategory = {
                userId: result.data.id,
                categoryId: Number(key),
              };
              addCategoryToUser(dataForUpsertionUserCategory);
            }
          }
          setAlertObject({
            ...SUCCESS_ALERT_OBJECT,
            message: `User <${email}> has been registered successfully!`,
            call_after_close: handlerOnSubmit
              ? handlerOnSubmit
              : gotoPresentation,
          });
          cleanUserData();
        },
        setAlertObject
      );
    } catch (error) {
      setAlertObject({
        ...ERORR_ALERT_OBJECT,
        message: `Unexpected error: ${error}`,
      });
    }
  };

  const handleSubmitUpdateUser = async (e) => {
    e.preventDefault();
    if (!checkSellerEmailUniqueness()) return;
     try {
      let dataForUpsertionUpdate = {
        id: user_info.id,
        fullName: fullName, 
        email: email, 
        permissions: user_info.permissions
      }; 
      if (password) {
        dataForUpsertionUpdate.password = password;
      } 
      
      const userImageURL = await uploadFirstImageIfProvided(imageFiles);
      if (userImageURL) dataForUpsertionUpdate.userImageURL = userImageURL;
      await handlingAfterFetch(
        userUpdate(dataForUpsertionUpdate),
        (result) => {
          updateUserSellers(result.data.id); 
          setAlertObject({
            ...SUCCESS_ALERT_OBJECT,
            message: `User <${user_info.fullName}> has been updated successfully!`,
            call_after_close: handlerOnSubmit
            ? handlerOnSubmit
            : closer(),
          });
          cleanUserData();
          user_info.permissions === 'admin' && setLoggedInUser({...result.data, password: ""});
        },
        setAlertObject
      );
      await handlingAfterFetch(
        updateCategoriesToUser(user_info.id, checkedCategories), 
         (result) => {
        }, 
        setAlertObject
      );  
    } catch (error) {
      setAlertObject({
        ...ERORR_ALERT_OBJECT,
        message: `Unexpected error: ${error}`,
      });
    } 
  };

  const handleChangeChecked = (event) => {
    setCheckedCourses({
      ...checkedCourses,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeCheckedCats = (event) => {
    setCheckedCategories({...checkedCategories, [event.target.name]: event.target.checked});
  };

  const handleOnChangeImg = (incommingFiles) => {
    setImageFiles(incommingFiles);
  };

  const handleOnDeleteImg = (id) => {
    setImageFiles(imageFiles.filter((x) => x.id !== id));
  };

  const handleOnSeeImg = (imageSource) => {
    setImageSrc(imageSource);
  };

  const deleteUserSeller = (seller) => {
    let updatedList = [...existingUserSellers];
    updatedList = updatedList.filter(userSellers => (seller.createdAt !== userSellers.createdAt));
    setExistingUserSellers(updatedList);
  }

  const addNewSeller = () => {
    const createdAt = new Date;
    setExistingUserSellers([
      ...existingUserSellers,
      { studentId: 0, sellerName, sellerEmail, createdAt: ''+createdAt },
    ]);
  };

  const additionalInfo = () => {
    if (userPermissions == PERMISSIONS_STUDENT) {
      return (
        <>
          <Button
            className="secondary-bckg light-bg seller-add"
            onClick={addNewSeller}
          >
            Add Seller
          </Button>
        </>
      );
    }
  };

  const handleChangeSeller = (value, type, key) => {
    if (type === "name") {
      existingUserSellers[key].sellerName = value;
    } else {
      existingUserSellers[key].sellerEmail = value;
    }
  };

  const handleChangeUserImage = () => {
    setNewUserImg(!newUserImg);
  };

  useEffect(() => {
    fetchData();
  }, []);


  
  return (
    <div className={user_info ? "update-user-block" : "register-block"}>
      <form onSubmit={user_info ? handleSubmitUpdateUser : handleSubmitSignUp} className={PERMISSIONS_ADMIN && 'fullwidth'}>
        <UserAllPropeties
          userPermissions={userPermissions}
          user_info={user_info}
        />
        {user_info && userPermissions === PERMISSIONS_STUDENT && (
          <>
            <h4>Categories</h4>
            <div className="assigned-categories">
              {existingCategories.map((catData) =>  (   
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedCategories["" + catData.id] ? true : false}
                      onChange={handleChangeCheckedCats}
                      name={"" + catData.id}
                      color="primary"     
                    />
                  }
                  label={catData.categoryName}
                />
              ))}
            </div>
          </>
        )}
        {user_info && (
          <div className="user_img">
            <p>
              <b>User Image: </b>
            </p>
            {user_info.userImageURL &&
              <div className="img-wrapper">
                <img src={SERVER_ENDPOINT + user_info.userImageURL}></img>
              </div>
            }
            <Button
              variant="contained"
              className="primary-disabled-bckg"
              onClick={handleChangeUserImage}
            >
              Change User Image
            </Button>
          </div>
        )}
        {newUserImg && (
          <Dropzone
            onChange={handleOnChangeImg}
            value={imageFiles}
            maxFiles={1}
            maxFileSize={3998000}
            accept={"image/*"}
          >
            {imageFiles.map((file) => (
              <FileItem
                key={file.id}
                {...file}
                onDelete={handleOnDeleteImg}
                onSee={handleOnSeeImg}
                preview
                info
                hd
              />
            ))}
          </Dropzone>
        )}
        {!user_info && (
          <Dropzone
            onChange={handleOnChangeImg}
            value={imageFiles}
            maxFiles={1}
            maxFileSize={3998000}
            accept={"image/*"}
          >
            {imageFiles.map((file) => (
              <FileItem
                key={file.id}
                {...file}
                onDelete={handleOnDeleteImg}
                onSee={handleOnSeeImg}
                preview
                info
                hd
              />
            ))}
          </Dropzone>
        )}
        {userPermissions === "student" ? <h3>Sellers Info</h3> : ""}
        {existingUserSellers.map((seller, key) => {
          return (
            <div className="seller-fields">
              <p>{key + 1}</p>
              <div className="fields">
                <TextField
                  variant="outlined"
                  fullWidth
                  id={`seller_name${key}`}
                  label="Seller Name"
                  name="seller name"
                  autoComplete="Seller name"
                  autoFocus
                  defaultValue={seller.sellerName}
                  onChange={(e) => {
                    handleChangeSeller(e.target.value, "name", key);
                  }}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  id={`seller_mail${key}`}
                  label="Seller Mail"
                  name="seller mail"
                  autoComplete="Seller mail"
                  autoFocus
                  defaultValue={seller.sellerEmail}
                  onChange={(e) => {
                    handleChangeSeller(e.target.value, "email", key);
                  }}
                />
                <Button className="secondary-disabled-bckg" onClick={(e) => {e.preventDefault(); deleteUserSeller(seller);}}> Delete Seller </Button>
              </div>
            </div>
          );
        })}
        {additionalInfo()}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className="primary-brand-bckg submit-btn"
        >
         
          {!user_info && userPermissions == PERMISSIONS_STUDENT && "Add VA"}
          {!user_info && userPermissions == PERMISSIONS_TEACHER && "Add Training Manager"} 
          {!user_info && userPermissions == PERMISSIONS_ADMIN && "Add Admin"}

          {user_info && userPermissions == PERMISSIONS_STUDENT && "SAVE VA"}
          {user_info && userPermissions == PERMISSIONS_TEACHER && "SAVE TM"}
          {user_info && userPermissions == PERMISSIONS_ADMIN && "SAVE"}
        </Button>
      </form>
      {(!user_info && userPermissions !== PERMISSIONS_ADMIN) && (
        <div className="courses-cats-info">
          <h4>Courses</h4>
          <div className="assigned-courses">
            {existingCourses.map((courseData) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedCourses["" + courseData.id] ? true : false}
                    onChange={handleChangeChecked}
                    name={"" + courseData.id}
                    color="primary"
                  />
                }
                label={courseData.courseName}
              />
            ))}
          </div>
          <h4>Categories</h4>

          <div className="assigned-categories">
            {existingCategories.map((catData) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedCategories["" + catData.id] ? true : false}
                    onChange={handleChangeCheckedCats}
                    name={"" + catData.id}
                    color="primary"
                  />
                }
                label={catData.categoryName}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSignUp;