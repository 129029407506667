import React, { useEffect, useState, useContext } from "react";
import Header from './Helpers/Header';
import PresentationAdd from './Presentations/PresentationAdd';
import CategoriesPopup from './Presentations/CategoriesPopup';
import PresentationBlock from "./Presentations/PresentationBlock";
import '../assets/scss/presentations.scss';
import AppContext from './App/AppContext';
import PresentationsContext from './Presentations/PresentationsContext';
import {handlingAfterFetch} from './Helpers/ToolsComponents';
import {
    fetchExistingPresentations, 
    fetchExistingPresentationCategories} from '../services/presentations_api';
import { Link } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';

function ScreenPresentationsForStudents(props) {
    
    const {setCurrentScreen,  setAlertObject} = useContext(AppContext);       
  
    const [existingPresentations, setExistingPresentations] = useState([]);
    const [existingCategories, setExistingCategories] = useState([]);

    const fetchData = async () => {
        handlingAfterFetch(
            fetchExistingPresentationCategories(), 
            (result) => {
                setExistingCategories(result.data);                       
            },
            setAlertObject
        );
        handlingAfterFetch(
            fetchExistingPresentations(), 
            (result) => {
                setExistingPresentations(result.data);                       
            },
            setAlertObject
        );
    };

    const addPresentationToList = (d) => setExistingPresentations([...existingPresentations, d]);
    
    const addCategoryToList = (d) => setExistingCategories([...existingCategories, d]);

    useEffect(() => {
        // code to run on component mount
        setCurrentScreen("Presentations")
        fetchData();        
    }, [])

    const presentationsContextData = { 
        existingPresentations, setExistingPresentations,
        existingCategories, setExistingCategories
    };
    
    return (
        <PresentationsContext.Provider value={presentationsContextData}>
            <Header />
            <div className="pres-container">   
                <div className="register-wrapper">
                    <Link to="/learning-system/home/" className="primary-brand-bckg system-black"> <HomeIcon/> Back to Home</Link>
                </div>        
                <div className="container-block1">
                    <h2>MY PRESENTATIONS</h2>
                        {existingCategories.map(cat => {
                            const filteredPresentations = existingPresentations.filter(pres => (pres.category === cat.id));
                            if (filteredPresentations.length === 0) return null;
                            return (
                                <div className="category-block">
                                    <h3>{cat.categoryName}</h3>
                                    <div className="item-blocks">
                                    {
                                        filteredPresentations.map(presentationData =>  
                                            <PresentationBlock 
                                                presentationData={presentationData} 
                                                key={presentationData.id} 
                                                existingPresentations={existingPresentations} 
                                                setExistingPresentations={setExistingPresentations} />)}
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
           
        </PresentationsContext.Provider>
    ); 
}
  export default ScreenPresentationsForStudents;