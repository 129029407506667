import React, { useState } from "react";
import user_img from "../../assets/images/user.png";
import { SERVER_ENDPOINT } from "../../services";
import ResolveAnswer from "./ResolveAnswer";
import Pagination from '@material-ui/lab/Pagination';
import { Box } from "@material-ui/core";

export default function Answers({states, whatCourse, isActive}) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const generate_items = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = whatCourse ? states : states.slice(startIndex, endIndex);

    return itemsToDisplay.map(item => {
      const {filteredUser, course, chapter, test, question:q, state} = item;
      if(!whatCourse){
        return (
          <div className="answer-block">
            <div className="student-info">
              <img
                src={
                  filteredUser && filteredUser.userImageURL
                    ? SERVER_ENDPOINT + filteredUser.userImageURL
                    : user_img
                }
              />
              <h5 className="student-name">
                {filteredUser
                  ? filteredUser.fullName
                  : "username"}
              </h5>
            </div>
            <h4 className="question">{q.questionContent}</h4>
            <div className="course-info">
              <p className="course-name">{course.courseName}</p>
              <p className="chapter-name">
                {chapter.chapterName} / {test.testName}
              </p>
            </div>
            <div className="answer">{state.answer}</div>
            <ResolveAnswer
              course={course}
              chapter={chapter}
              test={test}
              studentInfo={filteredUser}
              question={q.questionContent}
              studentAnswer={state.answer}
              state={state}
              generate_items={generate_items}
              qId={q.id}
            />
          </div> 
        );
      }else if(whatCourse === course.courseName){
          return (
              <div className="answer-block">
              <div className="student-info">
                <img
                  src={
                    filteredUser && filteredUser.userImageURL
                      ? SERVER_ENDPOINT + filteredUser.userImageURL
                      : user_img
                  }
                />
                <h5 className="student-name">
                  {filteredUser
                    ? filteredUser.fullName
                    : "username"}
                </h5>
              </div>
              <h4 className="question">{q.questionContent}</h4>
              <div className="course-info">
                <p className="course-name">{course.courseName}</p>
                <p className="chapter-name">
                  {chapter.chapterName} / {test.testName}
                </p>
              </div>
              <div className="answer">{state.answer}</div>
              <ResolveAnswer
                studentInfo={filteredUser}
                course={course}
                chapter={chapter}
                test={test}
                question={q.questionContent}
                studentAnswer={state.answer}
                state={state}
                generate_items={generate_items}
              />
            </div>
          )
      }else if(whatCourse === "No Course Assignment"){
        return(
            <h3 className="title">
                There is no current answers.
            </h3>
        )
      } else {
        // console.log("1",whatCourse);
        // console.log("2",course.courseName);
      }
    })
  }
 
  return (
    <>
      <div className="answers-content answ active">
        {generate_items()}
      </div>
      {!whatCourse &&
        <Box mt={5} mx={'auto'} width={'fit-content'}>
          <Pagination 
            count={Math.ceil(states.length/itemsPerPage)} 
            page={currentPage} size="large" 
            onChange={(e, value) => setCurrentPage(value)}
          />
        </Box>
      }
    </>
  );
}
